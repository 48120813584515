import React, { useEffect, useState, useCallback, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import Button from "shared/Button";
import { useSessionStorage } from "shared/@hooks/useSessionStorage";
import { useQueryClient } from "react-query";
import { QUERY_KEYS } from "shared/@utils/queryKeys";
import moment from "moment-timezone";

import styles from "./EditMajorV2EventPage.module.css";
import DeleteModal from "Events/Components/DeleteModal";
import UndoChangesModal from "Events/Components/UndoChangesModal";
import { EventPublishModal } from "Events/Components/EventPublishModal/EventPublishModal";
import { extractDateTime } from "shared/@utils/extractCombineDateTime";

import { useEditEventsPage } from "./useEditEventPage";
import { useCurrentRoute } from "shared/@hooks/useCurrentRoute";
import Loader from "shared/Loader";
import _ from "lodash";
import EventTypeV2Card from "Events/V2Components/EventTypeV2Card";
import EventV2InfoForm from "Events/V2Components/EventV2InfoForm";
import PublishingV2InfoForm from "Events/V2Components/PublishingV2InfoForm";
// import DateV2Form from "Events/V2Components/DateV2Form";
import VenueV2Form from "Events/V2Components/VenueV2Form";
import TicketingV2Form from "Events/V2Components/TicketingV2Form";
import NotesV2Form from "Events/V2Components/NotesV2Form";
import SessionsV2Form from "Events/V2Components/SessionsV2Form";

import formatDate from "shared/@utils/formatDate";
import formatFieldName from "shared/@utils/formatFieldName";
import { removeExtraLineSpaces } from "shared/@utils/removeExtraNewLines";
import { AlertModal } from "Events/Components/AlertModal/AlertModal";
import ADAV2Form from "Events/V2Components/ADAV2Form";
import VirtualV2Form from "Events/V2Components/VirtualV2Form";
import CircumstancesV2Form from "Events/V2Components/CircumstancesV2Form";
import DateV2Section from "Events/V2Components/DateV2Section";

export function EditMajorV2EventPage() {
  const history = useHistory();
  const queryClient = useQueryClient();

  const { eventId } = useParams();

  const {
    data: eventData,
    isLoading,
    refetch,
    eventEditPut,
    eventPublish,
  } = useEditEventsPage({ eventId });

  const { data: states } = useCurrentRoute();
  // const [eventData, setEventData] = useState(null);
  const [event, setEvent] = useState(null);

  const [showSavedModal, changeShowSavedModal] = useState(false);
  const [showRemoveModal, changeShowRemoveModal] = useState(false);
  const [undoChangesModal, setUndoChangesModal] = useState(false);
  const [removeChangesModal, setRemoveChangesModal] = useState(false);
  const [isAlertModalOpen, setIsAlertModalOpen] = useState(false);
  const [showUndoButton, setShowUndoButton] = useState(false);
  const [enablePublishButton, setEnablePublishButton] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [undoPath, setUndoPath] = useState("");
  const [isFormValid, setIsFormValid] = useState(true);
  const [triggerValidation, setTriggerValidation] = useState(false);
  const [undoCheckboxState, setUndoCheckboxState] = useState({});

  const [eventInfo, setEventInfo] = useState({
    event: {
      name: "",
      ruleset: "",
      location: "",
      jobCode: "",
      dates: {},
      country: "",
      city: "",
      state: "",
      notes: "",
      virtualEventLink: "",
      virtualEventType: "",
      securityNotes: "",
    },
    marketing: {
      title: "",
      description: "",
      tentative: "",
      visibleCalendar: "",
      visibleTo: "",
      bookingStatus: "",
      notes: "",
    },
    analytics: {
      sales: {
        soldOut: "",
        capacity: {
          purchase: 0,
          platinum: 0,
        },
      },
      countingTickets: "",
    },
    venue: {
      company: {},
      room: {},
    },
    sessions: [
      {
        type: "",
        sessionName: "",
        description: "",
        speakers: [],
        capacity: "",
        startDate: "",
        endDate: "",
        startTime: "",
        endTime: "",
      },
    ],
    ada: {
      saved: [],
      value: [],
    },
    tickets: {
      saved: [],
      value: [],
    },
    circumstances: "",
    speakers: [],
  });

  useEffect(() => {
    if (eventData) {
      setEvent(eventData);

      setEventInfo((prevState) => ({
        ...prevState,
        event: {
          name: eventData.event?.name,
          ruleset: eventData.event?.ruleset,
          location: eventData.event?.location,
          jobCode: eventData.event?.jobCode,
          city: eventData.event?.city || "",
          state: eventData.event?.state || "",
          country: eventData.event?.country || "",
          dates: eventData.event?.dates || "",
          notes: eventData.event?.notes || "",
          virtualEventLink: eventData.event?.virtualEventLink || "",
          virtualEventType: eventData.event?.virtualEventType || "",
          securityNotes: eventData.event?.securityNotes || "",
        },
        tickets: eventData.tickets?.value || [],
        marketing: {
          title: eventData.marketing?.title || "",
          description: eventData.marketing?.description || "",
          tentative: eventData.marketing?.tentative || false,
          visibleCalendar: eventData.marketing?.visibleCalendar || false,
          visibleTo: eventData.marketing?.visibleTo || "",
          bookingStatus: eventData.marketing?.bookingStatus || "",
          notes: eventData.marketing?.notes || "",
        },
        analytics: {
          countingTickets: eventData.analytics?.countingTickets || false,
          sales: {
            soldOut: eventData.analytics?.sales?.soldOut || false,
            capacity: {
              purchase: eventData.analytics?.sales?.capacity?.purchase || 0,
              platinum: eventData.analytics?.sales?.capacity?.platinum || 0,
            },
          },
        },
        venue: {
          company: eventData.venue.company || {},
          room: {
            saved: eventData.venue?.room?.saved || {},
            value: eventData.venue?.room?.value || {},
          },
        },
        sessions: {
          saved: eventData.sessions?.saved || [],
          value: eventData.sesisons?.value || [],
        },

        ada: {
          saved: eventData.ada?.saved || [],
          value: eventData.ada?.value || [],
        },

        tickets: {
          saved: eventData.tickets?.saved || [],
          value: eventData.tickets?.value || [],
        },

        speakers: eventData.speakers,

        //TODO: Need to add data for (hosts etc.)
      }));
    }
  }, [eventData]);

  // state to hold form data
  const [formData, setFormData] = useSessionStorage("formData", {
    venue: {
      state: "",
      city: "",
      venue: "",
      noVenue: false,
    },
  });

  // Check if saved value exists, excluding event.notes.saved from the check
  const checkSavedValues = (obj, parentKey = "") => {
    for (let key in obj) {
      // Skip checking for event.notes.saved and event.country.saved
      if ((parentKey === "event" && key === "notes") || key === "country")
        continue;

      if (parentKey === "venue" && (key === "company" || key === "room")) {
        const saved = obj[key].saved || {};
        const value = obj[key].value || {};
        for (let prop in saved) {
          if (saved[prop] !== null && saved[prop] !== value[prop]) {
            return true;
          }
        }
        continue;
      }

      if (typeof obj[key] === "object" && obj[key] !== null) {
        const currentPath = parentKey ? `${parentKey}.${key}` : key;

        if (obj[key].hasOwnProperty("saved") && obj[key].saved) {
          if (obj[key].hasOwnProperty("value")) {
            const saved = obj[key].saved;
            const value = obj[key].value;

            // If both saved and value are arrays, compare their lengths and contents
            if (Array.isArray(saved) && Array.isArray(value)) {
              if (
                saved.length === value.length &&
                saved.every((item, index) => item === value[index])
              ) {
                continue;
              }
            } else if (saved === value) {
              continue;
            }

            return true;
          }
        }

        if (checkSavedValues(obj[key], currentPath)) {
          return true;
        }
      }
    }
    return false;
  };

  //Determines the showing, hiding of buttons
  useEffect(() => {
    const unsavedChangesExist = checkSavedValues(eventInfo);

    setHasUnsavedChanges(unsavedChangesExist);
    setShowUndoButton(unsavedChangesExist);

    const shouldEnablePublish =
      unsavedChangesExist ||
      eventInfo?.event?.status?.value === "Unpublished" ||
      eventInfo?.event?.status?.value !== "Publishing";
    setEnablePublishButton(shouldEnablePublish);
  }, [eventInfo]);

  const handleTicketingForm = async (newTicketData) => {
    // console.log(newTicketData);

    // await eventEditPut.mutateAsync({ tickets: newTicketData });
    setEventInfo({
      ...eventInfo,
      tickets: {
        ...eventInfo.tickets,
        saved: newTicketData,
      },
    });
  };

  const handleEventTypeLocation = useCallback((selectedLocation) => {
    const newLocation =
      typeof selectedLocation === "string"
        ? selectedLocation.trim().toLowerCase()
        : "";

    setEventInfo((prevData) => {
      if (prevData.event.location.saved === newLocation) return prevData;

      const updateLocation = { event: { location: newLocation } };
      eventEditPut.mutateAsync(updateLocation);

      return {
        ...prevData,
        event: {
          ...prevData.event,
          location: { ...prevData.event.location, saved: newLocation },
        },
      };
    });
  }, []);

  const [undoIndex, setUndoIndex] = useState(null);
  const [undoField, setUndoField] = useState("");
  const [undoFieldDisplay, setUndoFieldDisplay] = useState("");
  const [original, setOriginal] = useState("");

  //Handles event info form
  const handleMarketingOnBlur = useCallback(
    _.debounce((obj, key, saved) => {
      if (saved === null) return;

      setEventInfo((prevData) => {
        const currentValue = _.get(prevData, `${obj}.${key}.saved`);

        if (!_.isEqual(currentValue, saved)) {
          const updatedData = _.set(
            _.cloneDeep(prevData),
            `${obj}.${key}.saved`,
            saved
          );

          const newValue = saved || null;
          eventEditPut.mutateAsync({ [obj]: { [key]: newValue } }).then(() => {
            setHasUnsavedChanges(true);
            // refetch();
          });

          return updatedData;
        }
        return prevData;
      });
    }, 300),
    []
  );

  //Deeply nested object
  const constructNestedUpdate = (path, value) => {
    const keys = path.split(".");
    const result = {};
    let current = result;

    keys.forEach((key, index) => {
      if (index === keys.length - 1) {
        current[key] = value;
      } else {
        current[key] = current[key] || {};
        current = current[key];
      }
    });

    return result;
  };

  //Publish info
  const handlePublishChange = useCallback(
    ({ path, value }) => {
      // Create a new promise that will be returned
      return new Promise((resolve, reject) => {
        const debouncedUpdate = _.debounce(() => {
          const newValue = value || null;
          const update = constructNestedUpdate(path, newValue);

          setEventInfo((prevEventInfo) =>
            _.merge(_.cloneDeep(prevEventInfo), update)
          );

          eventEditPut.mutateAsync(update, {
            onSuccess: () => {
              const significantPaths = [
                "analytics.sales.soldOut",
                "analytics.sales.capacity.purchase",
                "analytics.sales.capacity.platinum",
                "analytics.countingTickets",
                "marketing.visibleCalendar",
                "marketing.visibleTo",
                "marketing.bookingStatus",
                "marketing.tentative",
              ];
              const shouldInvalidate = significantPaths.some(
                (significantPath) => path.startsWith(significantPath)
              );

              if (shouldInvalidate) {
                queryClient.invalidateQueries(["event", eventId]);
              }
              resolve();
            },
            onError: (err) => {
              console.error("Update failed:", err);
              reject(err);
            },
          });
        }, 300);

        debouncedUpdate();
      });
    },
    [queryClient, eventId, eventEditPut]
  );

  const handleSpeakerChange = async (speakers, isRemoving) => {
    if (isRemoving) {
      await eventEditPut.mutateAsync({
        speakers: [
          {
            ...speakers,
            undo: true,
          },
        ],
      });
    } else {
      await eventEditPut.mutateAsync({
        speakers: speakers.filter(
          (speaker) =>
            !eventInfo?.speakers?.saved?.find(
              (speak) => speak.wwgId === speaker.wwgId
            )
        ),
      });
    }
  };

  const handleUndoSessions = async () => {
    const sessionId = undoPath.split(".")[1];
    const propToUpdate = undoPath.split(".")[2];
    const sessionToUpdate = eventInfo?.sessions?.saved.find(
      (session) => session.id == sessionId
    );
    if (propToUpdate == "session") {
      await eventEditPut.mutateAsync({
        sessions: [{ ...sessionToUpdate, undo: true }],
      });
    } else {
      if (propToUpdate == "speakers") {
        const formattedSpeakersForClearing = sessionToUpdate.speakers.map(
          (speaker) => {
            return {
              ...speaker,
              undo: true,
            };
          }
        );
        await eventEditPut.mutateAsync({
          sessions: [
            { ...sessionToUpdate, speakers: formattedSpeakersForClearing },
          ],
        });
      } else {
        await eventEditPut.mutateAsync({
          sessions: [{ ...sessionToUpdate, [propToUpdate]: null }],
        });
      }
    }
  };

  const handleUndoSpeakers = async () => {
    const formattedSpeakersForClearing = eventInfo?.speakers?.saved.map(
      (speaker) => {
        return {
          ...speaker,
          undo: true,
        };
      }
    );
    await eventEditPut.mutateAsync({ speakers: formattedSpeakersForClearing });
    // if (eventInfo?.speakers?.value.length > 0) {
    //   await eventEditPut.mutateAsync({ speakers: eventInfo?.speakers?.value });
    // }
  };

  //Dates
  const handleDateChange = async (key, newValue, isTime) => {
    // Retrieve the previous value for comparison
    const prevValue = eventInfo?.dates?.[key] || "";

    // Check if the newValue is different from the previous value
    if (newValue !== prevValue) {
      const newDate = {
        event: {
          dates: {
            [key]: newValue,
          },
        },
      };

      await eventEditPut.mutateAsync(newDate);

      setEventInfo((prev) => ({
        ...prev,
        dates: {
          ...prev.dates,
          [key]: newValue,
        },
      }));

      // If there's a time, refetch for timezone data to display
      const newTime = moment(newValue).utc().format("HH:mm");
      if (isTime || newTime !== "00:00") {
        queryClient.invalidateQueries(["event", eventId]);
      }
    }
  };

  const debouncedHandleDateChangeRef = useRef(
    _.debounce(handleDateChange, 300)
  );

  const handleDateTimeChange = (key, newValue, isTime) => {
    debouncedHandleDateChangeRef.current(key, newValue, isTime);
  };

  //Notes
  const handleNotesChange = (dataType, newValue) => {
    newValue = newValue.replace(/↵/g, "\r\n").trim();

    newValue = newValue === "" ? null : newValue;

    setEventInfo((prev) => {
      // Early exit if there's no change
      const currentSavedValue = prev[dataType]?.notes?.saved?.trim() || "";
      if (newValue === currentSavedValue) {
        return prev;
      }

      const updatedInfo = {
        ...prev,
        [dataType]: {
          ...prev[dataType],
          notes: {
            ...prev[dataType].notes,
            saved: newValue,
          },
        },
      };

      debouncedHandleNotesChangeRef.current(dataType, {
        [dataType]: { notes: newValue },
      });
      return updatedInfo;
    });
  };

  const debouncedHandleNotesChangeRef = useRef(
    _.debounce((dataType, newNotes) => {
      eventEditPut.mutateAsync(newNotes);
    }, 300)
  );

  //Venue
  const handleVenueChange = (newVenueData) => {
    debouncedEventEditPut(newVenueData);

    setEventInfo((prevEventInfo) => ({
      ...prevEventInfo,
      venue: newVenueData.venue,
    }));
  };

  const debouncedEventEditPut = useRef(
    _.debounce((newVenueData) => {
      eventEditPut.mutateAsync(newVenueData);
    }, 100)
  ).current;

  //Virtual
  const handleVirtualForm = (newValue, field) => {
    newValue = newValue === "" ? null : newValue;
    setEventInfo((prev) => {
      // Early exit if there's no change
      const currentSavedValue = prev.event?.[field]?.saved || "";
      if (newValue === currentSavedValue) {
        return prev;
      }

      const updatedInfo = {
        ...prev,
        event: {
          ...prev.event,
          [field]: { ...prev.event[field], saved: newValue },
        },
      };

      debouncedHandleVirtualChangeRef.current({
        event: { [field]: newValue },
      });

      return updatedInfo;
    });
  };

  const debouncedHandleVirtualChangeRef = useRef(
    _.debounce((updatedEvent) => {
      eventEditPut.mutateAsync(updatedEvent).catch((error) => {
        console.error("Backend update failed:", error);
      });
    }, 800)
  );

  // Sessions
  const handleSessions = async (sessionData) => {
    const hasId = sessionData._id;
    const hasUndo = sessionData.undo;

    if (hasId) {
      // if (hasUndo) {
      //   await eventEditPut.mutateAsync({
      //     sessions: [
      //       sessionData
      //     ]
      //   })
      // } else {
      await eventEditPut.mutateAsync({
        sessions: [sessionData],
      });
      // }
    } else {
      await eventEditPut.mutateAsync({
        sessions: [{}],
      });
    }
    // setEventInfo((prevState) => {
    //   const updatedSessions = [...prevState.sessions];
    //   updatedSessions[index] = {
    //     ...updatedSessions[index],
    //     [field]: value,
    //   };
    //   return {
    //     ...prevState,
    //     sessions: updatedSessions,
    //   };
    // });
  };

  // ADA
  const handleADAQuestions = (newEntries) => {
    setEventInfo((prev) => {
      const currentSavedValue = prev.ada?.saved || null;
      if (newEntries === currentSavedValue) {
        return prev;
      }

      // Update the state with the new value
      const updatedInfo = {
        ...prev,
        ada: { ...prev.ada, saved: newEntries },
      };

      // Trigger the debounced backend call
      debouncedHandleADAQuestionsRef.current({
        ada: newEntries,
      });

      return updatedInfo;
    });
  };

  // Debounced backend call reference
  const debouncedHandleADAQuestionsRef = useRef(
    _.debounce(async (updatedEvent) => {
      try {
        await eventEditPut.mutateAsync(updatedEvent);
      } catch (error) {
        console.error("Backend update failed:", error);
      }
    }, 800)
  );

  const handleCircumstances = (newCircumstances) => {
    const newValue = newCircumstances === "" ? null : newCircumstances;
    setEventInfo((prev) => {
      const currentSavedValue = prev.event?.securityNotes?.saved || "";
      if (newValue === currentSavedValue) {
        return prev;
      }

      const updatedInfo = {
        ...prev,
        event: {
          ...prev.event,
          securityNotes: { ...prev.event.securityNotes, saved: newValue },
        },
      };

      debouncedHandleCircumstancesChangeRef.current({
        event: { securityNotes: newValue },
      });

      return updatedInfo;
    });
  };

  const debouncedHandleCircumstancesChangeRef = useRef(
    _.debounce((updatedEvent) => {
      eventEditPut.mutateAsync(updatedEvent).catch((error) => {
        console.error("Backend update failed:", error);
      });
    }, 800)
  );

  //Function handles the undo changes
  const handleUndoChangeAndUpdate = async () => {
    let updatedChanges = {};
    const eventObjects = ["event", "marketing", "analytics", "venue"];
    let shouldInvalidate = false;

    if (undoPath?.includes("sessions")) {
      handleUndoSessions();
    }

    if (undoField == "speakers") {
      handleUndoSpeakers();
    }

    eventObjects.forEach((eventObject) => {
      let tempContext = {};
      if (eventInfo[eventObject]) {
        const isEventNotes = eventObject === "event" && undoField === "notes";
        const isMarketingNotes =
          eventObject === "marketing" && undoField === "notes";

        const proceed =
          undoField !== "notes" || isEventNotes || isMarketingNotes;

        if (proceed) {
          if (eventObject === "venue" && undoField === "venue") {
            // Set saved properties to current value properties for company and room
            tempContext.company = {
              ...eventInfo.venue.company,
              saved: { ...eventInfo.venue.company.value },
            };
            tempContext.room = {
              ...eventInfo.venue.room,
              saved: { ...eventInfo.venue.room.value },
            };
            updatedChanges[eventObject] = {
              ...eventInfo[eventObject],
              ...tempContext,
            };
            shouldInvalidate = true;
          } else if (
            findAndUpdateField(eventInfo[eventObject], undoField, tempContext)
          ) {
            updatedChanges[eventObject] = {
              ...eventInfo[eventObject],
              ...tempContext,
            };
            shouldInvalidate = true;
          }
        }
      }
    });

    if (Object.keys(updatedChanges).length > 0) {
      await eventEditPut.mutateAsync(updatedChanges, {
        onSuccess: () => {
          if (shouldInvalidate) {
            queryClient.invalidateQueries(["event", eventId]);
          }
        },
      });
    }
    setRemoveChangesModal(false);
  };

  const findAndUpdateField = (obj, field, context) => {
    let fieldUpdated = false;

    if (field === "venue") {
      // Special handling for venue: set saved properties to the current value
      if (obj.company && obj.company.value) {
        context.company = {
          ...obj.company,
          saved: { ...obj.company.value }, // Set saved to the current value
        };
        fieldUpdated = true;
      }
      if (obj.room && obj.room.value) {
        context.room = {
          ...obj.room,
          saved: { ...obj.room.value }, // Set saved to the current value
        };
        fieldUpdated = true;
      }
    } else if (field === "notes") {
      // Existing handling for notes
      if (
        undoPath === "event.notes" &&
        obj === eventInfo.event &&
        field in obj
      ) {
        context[field] = null;
        fieldUpdated = true;
      } else if (
        undoPath === "marketing.notes" &&
        obj === eventInfo.marketing &&
        field in obj
      ) {
        context[field] = null;
        fieldUpdated = true;
      }
    } else if (field in obj) {
      context[field] = null;
      fieldUpdated = true;
    } else {
      Object.keys(obj).forEach((key) => {
        if (obj[key] !== null && typeof obj[key] === "object") {
          let nestedContext = {};
          if (findAndUpdateField(obj[key], field, nestedContext)) {
            context[key] = { ...context[key], ...nestedContext };
            fieldUpdated = true;
          }
        }
      });
    }

    return fieldUpdated;
  };

  setTimeout(() => {
    const unsavedChangesExist = checkSavedValues(eventInfo);
    setShowUndoButton(unsavedChangesExist);
  }, 100);

  const handleUndoButtonClick = (
    index,
    path,
    field,
    originalValue,
    fieldDisplay
  ) => {
    setRemoveChangesModal(true);
    setUndoIndex(index);
    setUndoField(field);
    setUndoPath(path);
    setOriginal(originalValue);
    setUndoFieldDisplay(fieldDisplay);
  };

  //Display state in words, don't display if value is "0"
  const getStateNameById = (id) => {
    if (id === "0") return "";

    const state = states?.find(
      (state) => state?.stateId?.toString() === id?.toString()
    );

    if (state) {
      return `${state.abbreviation} - ${state.state}`;
    } else {
      return null;
    }
  };

  const handleViewCreatedEvent = () => {
    changeShowSavedModal(false);
    history.push(`/events/view/event/majorv2/${eventId}`);
  };

  const onPublish = () => {
    eventPublish.mutateAsync();
  };

  const handlePublish = () => {
    changeShowSavedModal(true);

    onPublish();
  };

  if (isLoading && eventInfo) {
    return (
      <div className={styles.loader}>
        <div>
          <Loader />
        </div>
        <div className={styles.loaderText}>Loading Edit Major V2.0</div>
      </div>
    );
  }

  const countryIdName = {
    220: "U.S.",
    38: "CDN",
    40: "JAMAICA",
    221: "JAMAICA",
    300: "ANZ",
    151: "ANZ",
  };

  const fieldChanges = [
    {
      field: "Display Name",
      field_name: "title",
      path: "marketing.title",
      original_value: eventInfo?.marketing?.title?.value,
      saved_value: eventInfo?.marketing.title?.saved,
    },
    {
      field: "Event Description",
      field_name: "description",
      path: "marketing.description",
      original_value: eventInfo?.marketing.description?.value,
      saved_value: eventInfo?.marketing.description?.saved,
    },
    {
      field: "Speakers",
      field_name: "speakers",
      path: "speakers",
      original_value: eventInfo?.speakers?.value,
      saved_value: eventInfo?.speakers?.saved,
    },
    {
      field: "City",
      field_name: "city",
      path: "event.city",
      original_value: eventInfo.event.city?.value,
      saved_value: eventInfo.event.city?.saved,
    },
    {
      field: "Country",
      field_name: "country",
      path: "event.country",
      original_value: countryIdName[eventInfo.event.country?.value],
      saved_value: countryIdName[eventInfo.event.country?.saved],
    },
    {
      field: "State",
      field_name: "state",
      path: "event.state",
      original_value: eventInfo.event.state?.value
        ? getStateNameById(eventInfo.event.state?.value)
        : "",
      saved_value: eventInfo.event.state?.saved
        ? getStateNameById(eventInfo.event.state?.saved)
        : "",
    },
    {
      field: "Booking Status",
      field_name: "bookingStatus",
      path: "marketing.bookingStatus",
      original_value: eventInfo.marketing?.bookingStatus?.value,
      saved_value: eventInfo.marketing?.bookingStatus?.saved,
    },
    {
      field: "Visible on Calendar",
      field_name: "visibleCalendar",
      path: "marketing.visibleCalendar",
      original_value: eventInfo.marketing?.visibleCalendar?.value ? "Yes" : "",
      saved_value:
        eventInfo.marketing?.visibleCalendar?.saved === false
          ? "No"
          : eventInfo.marketing?.visibleCalendar?.saved
          ? "Yes"
          : "",
    },
    {
      field: "Visible To",
      field_name: "visibleTo",
      path: "marketing.visibleTo",
      original_value: eventInfo.marketing?.visibleTo?.value,
      saved_value: eventInfo.marketing?.visibleTo?.saved,
    },
    {
      field: "Tentative",
      field_name: "tentative",
      path: "marketing.tentative",
      original_value: eventInfo.marketing?.tentative?.value ? "Yes" : "",
      saved_value:
        eventInfo.marketing?.tentative?.saved === false
          ? "No"
          : eventInfo.marketing?.tentative?.saved
          ? "Yes"
          : "",
    },
    {
      field: "Sold Out",
      field_name: "soldOut",
      path: "analytics.sales.soldOut",
      original_value: eventInfo.analytics?.sales?.soldOut?.value ? "Yes" : "",
      saved_value:
        eventInfo.analytics?.sales?.soldOut?.saved === false
          ? "No"
          : eventInfo.analytics?.sales?.soldOut?.saved
          ? "Yes"
          : "",
    },
    {
      field: "Counting Tickets",
      field_name: "countingTickets",
      path: "analytics.countingTickets",
      original_value: eventInfo?.analytics?.countingTickets?.value ? "Yes" : "",
      saved_value:
        eventInfo?.analytics?.countingTickets?.saved === false
          ? "No"
          : eventInfo?.analytics?.countingTickets?.saved
          ? "Yes"
          : "",
    },
    {
      field: "Purchase Capacity",
      field_name: "purchase",
      path: "analytics.sales.capacity.purchase",
      original_value: eventInfo.analytics?.sales.capacity?.purchase?.value,
      saved_value: eventInfo.analytics?.sales.capacity?.purchase?.saved,
    },
    {
      field: "Platinum Capacity",
      field_name: "platinum",
      path: "analytics.sales.capacity.platinum",
      original_value: eventInfo.analytics?.sales.capacity?.platinum?.value,
      saved_value: eventInfo.analytics?.sales.capacity?.platinum?.saved,
    },
    {
      field: "Virtual Event Type",
      field_name: "virtualEventType",
      path: "event.virtualEventType",
      original_value: eventData.event?.virtualEventType?.value,
      saved_value: eventData.event?.virtualEventType?.saved,
    },
    {
      field: "Event Link",
      field_name: "virtualEventLink",
      path: "event.virtualEventLink",
      original_value: eventData.event?.virtualEventLink?.value,
      saved_value: eventData.event?.virtualEventLink?.saved,
    },
    {
      field: "Event Venue",
      field_name: "venue",
      path: "venue",
      original_value: `${
        eventInfo?.venue?.company?.value?.company
          ? eventInfo?.venue?.company?.value?.company
          : ""
      }${
        eventInfo?.venue?.room?.value?.room
          ? ` (${eventInfo?.venue?.room?.value?.room})`
          : ""
      }`,
      saved_value: `${
        eventInfo?.venue?.company?.saved?.company
          ? eventInfo?.venue?.company?.saved?.company
          : ""
      }${
        eventInfo?.venue?.room?.saved?.room
          ? ` (${eventInfo?.venue?.room?.saved?.room})`
          : ""
      }`,
    },
    {
      field: "Sessions",
      field_name: "sessions",
      path: "sessions",
      original_value: eventInfo.sessions?.value,
      saved_value: eventInfo.sessions?.saved,
    },
    {
      field: "Security Notes",
      field_name: "securityNotes",
      path: "event.securityNotes",
      original_value: eventInfo.event?.securityNotes?.value
        ? removeExtraLineSpaces(
            decodeURI(eventInfo.event?.securityNotes?.value)
          )
        : "",
      saved_value: eventInfo.event?.securityNotes?.saved
        ? removeExtraLineSpaces(
            decodeURI(eventInfo.event?.securityNotes?.saved)
          )
        : "",
    },
    {
      field: "Internal Notes",
      field_name: "notes",
      path: "event.notes",
      original_value: eventInfo.event?.notes?.value
        ? removeExtraLineSpaces(decodeURI(eventInfo.event?.notes?.value))
        : "",
      saved_value: eventInfo.event?.notes?.saved
        ? removeExtraLineSpaces(decodeURI(eventInfo.event?.notes?.saved))
        : "",
    },
    {
      field: "Calendar Notes",
      field_name: "notes",
      path: "marketing.notes",
      original_value: eventInfo.marketing?.notes?.value
        ? removeExtraLineSpaces(decodeURI(eventInfo.marketing?.notes?.value))
        : "",
      saved_value: eventInfo.marketing?.notes?.saved
        ? removeExtraLineSpaces(decodeURI(eventInfo.marketing?.notes?.saved))
        : "",
    },
  ].filter((change) => {
    if (
      ["countingTickets", "soldOut", "tentative", "visibleCalendar"].includes(
        change.field_name
      )
    ) {
      const isOriginalUnselected =
        !change.original_value || change.original_value === "No";
      const isSavedNo = change.saved_value === "No";
      const isSavedYes = change.saved_value === "Yes";

      return (
        !(isOriginalUnselected && isSavedNo) &&
        !(!isOriginalUnselected && isSavedYes)
      );
    }

    if (
      Array.isArray(change.original_value) &&
      change.original_value.length === 0 &&
      Array.isArray(change.saved_value) &&
      change.saved_value.length === 0
    ) {
      return false;
    }

    return true;
  });

  const capitalizeFirstLetter = (string) => {
    if (!string) return "";

    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const datesUndoChanges = (eventInfo) => {
    const dateFields = eventInfo?.event?.dates || {};
    const acronyms = ["SLM"];

    const undoChanges = Object.entries(dateFields).reduce((acc, [key, obj]) => {
      if (key.endsWith("Date") && obj?.saved) {
        const fieldName = key
          .replace("StartDate", " Begin")
          .replace("EndDate", " End")
          .replace("Date", "")
          .replace(/([A-Z])/g, " $1")
          .trim()
          .split(" ")
          .map((word) =>
            acronyms.includes(word.toUpperCase())
              ? word.toUpperCase()
              : word.charAt(0).toUpperCase() + word.slice(1)
          )
          .join(" ");

        acc.push({
          field: capitalizeFirstLetter(fieldName),
          field_name: key,
          original_value:
            obj.value === "Invalid Date" ? extractDateTime(obj.value) : null,
          saved_value: extractDateTime(obj.saved),
        });
      }
      return acc;
    }, []);

    return undoChanges;
  };

  const dateChanges = datesUndoChanges(eventInfo);

  //Handles back button
  const handleBackClick = () => {
    const displayName =
      eventInfo.marketing.title.saved === null ||
      eventInfo.marketing.title.saved === "";

    // Trigger validation
    setTriggerValidation((prev) => !prev);

    setTimeout(() => {
      if (displayName) {
        setIsAlertModalOpen(true);
        return;
      }

      if ((isFormValid && hasUnsavedChanges) || !displayName) {
        queryClient.invalidateQueries(QUERY_KEYS.events("25"));
        queryClient.invalidateQueries(QUERY_KEYS.events("50"));
        queryClient.invalidateQueries(QUERY_KEYS.events("all"));
        queryClient.invalidateQueries(QUERY_KEYS.archivedEvents("25"));
        queryClient.invalidateQueries(QUERY_KEYS.archivedEvents("50"));
        queryClient.invalidateQueries(QUERY_KEYS.archivedEvents("all"));
        history.push("/events/events-details", { refetch: true });
        refetch();
      }
    }, 0);
  };

  const handleCloseAlert = () => {
    setIsAlertModalOpen(false);
  };

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <div>
          <span className={styles.backButton} onClick={handleBackClick}>
            {"< Back"}
          </span>
          <h1 className={styles.title}>EDIT EVENT - MAJOR V2.0</h1>
        </div>

        <div className={styles.buttonContainer}>
          {showUndoButton && (
            <Button
              className={styles.undoButton}
              children={"UNDO CHANGES"}
              onClick={() => setUndoChangesModal(true)}
            />
          )}

          <Button
            className={
              eventInfo?.event?.status?.value === "Publishing"
                ? styles.greenButton
                : styles.saveButton
            }
            children={
              eventInfo?.event?.status?.value === "Publishing"
                ? "PUBLISHING"
                : "PUBLISH"
            }
            onClick={() => handlePublish()}
            disabled={!enablePublishButton}
          />
        </div>
      </div>

      <div className={styles.bodyContainer}>
        {eventInfo && (
          <EventTypeV2Card
            eventInfo={eventInfo?.event}
            onChange={handleEventTypeLocation}
          />
        )}

        <div className={styles.cardContainer}>
          {eventInfo && (
            <>
              <EventV2InfoForm
                marketingInfo={eventInfo}
                onBlur={handleMarketingOnBlur}
                pageMode="edit"
                triggerValidation={triggerValidation}
                onDateTimeChange={handleDateTimeChange}
                section="Event Information"
                onSpeakerChange={(speakers, isRemoving) =>
                  handleSpeakerChange(speakers, isRemoving)
                }
              />
            </>
          )}
        </div>
        <div className={styles.cardContainer}>
          {eventInfo && (
            <PublishingV2InfoForm
              publishingInfo={eventInfo}
              onPublishChange={handlePublishChange}
              pageMode="edit"
              onDateTimeChange={handleDateTimeChange}
              section="Publishing Information"
            />
          )}
        </div>

        {/* {eventInfo?.event?.location?.value === "onsite" && (
          <div className={styles.cardContainer}>
            <VenueV2Form
              venueInfo={eventInfo}
              onVenueChange={handleVenueChange}
              pageMode="edit"
            />
          </div>
        )} */}

        {eventInfo?.event?.location?.value === "onsite" ? (
          <div className={styles.cardContainer}>
            <VenueV2Form
              venueInfo={eventInfo}
              onVenueChange={handleVenueChange}
              pageMode="edit"
            />
          </div>
        ) : (
          <div className={styles.cardContainer}>
            <VirtualV2Form
              type={
                eventInfo.event?.virtualEventType?.saved ||
                eventInfo.event?.virtualEventType?.saved
              }
              link={
                eventInfo.event?.virtualEventLink?.saved ||
                eventInfo.event?.virtualEventLink?.value
              }
              onVirtualChange={handleVirtualForm}
            />
          </div>
        )}

        <div className={styles.cardContainer}>
          {eventInfo && (
            <SessionsV2Form
              sessions={eventInfo.sessions}
              setSessions={handleSessions}
              // onAddSpeaker={(speakers, isRemoving) => handleSpeakerSessionChange(speakers, isRemoving)}
              eventData={eventData}
            />
          )}
        </div>

        <div className={styles.cardContainer}>
          <TicketingV2Form
            ticketSets={
              eventInfo.tickets?.saved.length > 0
                ? eventInfo.tickets?.saved
                : eventInfo.tickets?.value
            }
            setTicketSets={(newTicketData) =>
              handleTicketingForm(newTicketData)
            }
            isVirtual={
              eventInfo?.event?.location?.value === "onsite" ? false : true
            }
            isMajor={true}
          />
        </div>

        <div className={styles.cardContainer}>
          {eventInfo && (
            <ADAV2Form
              entries={
                eventInfo.ada?.saved.length > 0
                  ? eventInfo.ada?.saved
                  : eventInfo.ada?.value
              }
              setEntries={(newEntries) => handleADAQuestions(newEntries)}
            />
          )}
        </div>

        <div className={styles.cardContainer}>
          {eventInfo && (
            <CircumstancesV2Form
              circumstanceNotes={eventInfo.event?.circumstances}
              setCircumstanceNotes={handleCircumstances}
            />
          )}
        </div>

        <div className={styles.cardContainer}>
          {eventInfo && (
            <NotesV2Form
              notesData={eventInfo}
              onNotesChange={handleNotesChange}
              pageMode="edit"
            />
          )}
        </div>
      </div>
      <div className={styles.footerContainer}>
        <Button
          className={
            eventInfo?.event?.status?.value === "Publishing"
              ? styles.greenButton
              : styles.saveButton
          }
          children={
            eventInfo?.event?.status?.value === "Publishing"
              ? "PUBLISHING"
              : "PUBLISH"
          }
          onClick={() => handlePublish()}
          disabled={!enablePublishButton}
        />
      </div>

      <UndoChangesModal
        isOpen={undoChangesModal}
        onCollapse={() => setUndoChangesModal(false)}
        editedEvent={[...fieldChanges, ...dateChanges].filter(
          (change) => change.saved_value
        )}
        onClick={handleUndoButtonClick}
      />

      <EventPublishModal
        isOpen={showSavedModal}
        onCollapse={() => changeShowSavedModal(false)}
        onClick={() => history.push("/events/events-details")}
        onView={() => handleViewCreatedEvent()}
      />

      <DeleteModal
        header={"REMOVE CHANGES"}
        description={`Are you sure you want to undo the changes made to ${formatFieldName(
          undoFieldDisplay
        )}?`}
        isOpen={removeChangesModal}
        onCollapse={() => setRemoveChangesModal(false)}
        onClick={handleUndoChangeAndUpdate}
        btnName={"REMOVE"}
      />

      <AlertModal isOpen={isAlertModalOpen} onClose={handleCloseAlert} />
    </div>
  );
}
