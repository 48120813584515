import React, { useState } from "react";
import PropTypes from "prop-types";
import styles from "./RulesetCard.module.css";
import Card from "../../../shared/Card";

import cardStyles from "../../V1Components/SharedCardStyles.module.css";
import NewEventModal from "Events/Components/NewEventModal";

/**
 * Interactive component that displays the rulesets information.
 */
export function RulesetCard({ rules, events, name, id, onCreateEvent }) {
  const [createNewOpened, setCreateNewOpened] = useState(false);
  const [preselectedEventName, setPreselectedEventName] = useState("");

  const cardContents = (
    <div className={styles.container}>
      <div className={styles.rulesetTitle}>
        <div className={styles.ruleset}>{name}</div>
      </div>
      <div>
        {rules.map((rule, index) => (
          <div className={styles.rulesetRow}>
            <div className={styles.rulesetRowTitle}>
              {index == 0 ? "Rules" : ""}
            </div>
            <div className={styles.rulesetRowDesc}>{rule.rule}</div>
          </div>
        ))}

        {events.map((event, index) => (
          <div className={styles.rulesetRow}>
            <div className={styles.rulesetRowTitle}>
              {index == 0 ? "Events" : ""}
            </div>
            <div className={styles.rulesetRowDesc}>
              <span>{event.name}</span>
              <p
                className={styles.createBtn}
                onClick={() => {
                  setCreateNewOpened(true);
                  setPreselectedEventName(event.name);
                }}
              >
                Create
              </p>
            </div>
          </div>
        ))}

        {/* {etc.map((item, index) => (
          <div key={index} className={styles.rulesetRow}>
            <div className={styles.rulesetRowTitle}>ETC.</div>
            <div className={styles.rulesetRowDesc}>{item}</div>
          </div>
        ))} */}
      </div>

      <NewEventModal
        isOpen={createNewOpened}
        onCollapse={() => setCreateNewOpened(false)}
        onEdit={false}
        selectedRuleset={name}
        selectedEventName={preselectedEventName}
        isFromRuleset={true}
      />
    </div>
  );

  return <Card children={cardContents} />;
}

RulesetCard.propTypes = {
  // Array of rules
  rules: PropTypes.array,
  // Displays a list of events with view btns
  events: PropTypes.array,
  // Name displayed at top of card
  name: PropTypes.string,
};
