import React from "react";
import PropTypes from "prop-types";
import Card from "shared/Card";

import InputItem from "Events/Components/InputItem";
import TextArea from "shared/@forms/TextArea";
import Button from "shared/Button";
import AddButton from "Events/Components/AddButton";
import styles from "./ADAV2Form.module.css";
import { ImagePicker } from "Events/Components/ImagePicker/ImagePicker";

export function ADAV2Form({ entries, setEntries }) {
  const handleTextAreaChange = (newValue, entry) => {
    const newEntries = entries.map((e) =>
      e.id === entry.id ? { ...e, param1: newValue } : e
    );
    setEntries(newEntries);
  };
  const handleIconChange = (icon, entry) => {
    // Check if the icon is a valid file
    if (!icon || !(icon instanceof File)) {
      console.error("Invalid icon file.");
      return;
    }

    // Function to convert the icon to Base64
    const convertToBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        // Event listener for successful file reading
        reader.onload = () => {
          resolve(reader.result);
        };

        reader.onerror = (error) => {
          reject(error);
        };

        // Read the file as a Data URL (Base64 string)
        reader.readAsDataURL(file);
      });
    };

    // Convert the file to Base64 and handle the result
    convertToBase64(icon)
      .then((base64Image) => {
        const newValue = base64Image;

        const newEntries = entries.map((e) =>
          e.id == entry.id ? { ...e, icon: newValue } : e
        );
        setEntries(newEntries);
      })
      .catch((error) => {
        console.error("Error converting icon to Base64:", error);
      });
  };
  const handleInputChange = (newValue, paramNumber, entry) => {
    const newEntries = entries.map((e) =>
      e.id === entry.id ? { ...e, [paramNumber]: newValue } : e
    );
    setEntries(newEntries);
  };

  const addNew = () => {
    setEntries([...entries, {}]);
  };

  const removeEntry = (entry) => {
    const newEntries = entries.filter((e) => e.id !== entry.id);
    setEntries(newEntries);
  };

  function capitalizeFirstLetter(str) {
    if (!str) return "";
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const renderParams = (entry) => {
    //Every field except for Description has the same ID
    if (entry.component == "description") {
      return (
        <div className={styles.paramContainer}>
          <div className={styles.paramItemDescription}>
            <div className={styles.type}>Text</div>
            <div className={styles.fieldDescription}>
              <TextArea
                value={entry.param1}
                onInput={(e) => handleTextAreaChange(e.target.value, entry)}
              />
            </div>
          </div>
        </div>
      );
    } else if (entry.component == "wheelchair") {
      //Add Icons
      return (
        <div className={styles.paramContainer}>
          <div className={styles.paramItem}>
            <div className={styles.type}>Option 1</div>
            <div className={styles.iconContainer}>
              <ImagePicker
                changeImageItem={(icon) => handleIconChange(icon, entry)}
                isADA={true}
                adaIMGSRC={entry.icon ? entry.icon : null}
              />
            </div>
            <div className={styles.field}>
              <input
                className={styles.fieldInput}
                placeholder={"Enter Text Here..."}
                value={entry.param1}
                onChange={(e) =>
                  handleInputChange(e.target.value, "param1", entry)
                }
              />
            </div>
          </div>
          <div className={styles.paramItem}>
            <div className={styles.type}>Option 2</div>
            <div className={styles.iconContainer}>
              <img className={styles.icon} />
            </div>
            <div className={styles.field}>
              <input
                className={styles.fieldInput}
                placeholder={"Enter Text Here..."}
                value={entry.param2}
                onChange={(e) =>
                  handleInputChange(e.target.value, "param2", entry)
                }
              />
            </div>
          </div>
        </div>
      );
    } else if (entry.component == "asl") {
      //Add Icon/s
      return (
        <div className={styles.paramContainer}>
          <div className={styles.paramItem}>
            <div className={styles.type}>Text</div>
            <div className={styles.iconContainer}>
              <ImagePicker
                changeImageItem={(icon) => handleIconChange(icon, entry)}
                isADA={true}
                adaIMGSRC={entry.icon ? entry.icon : null}
              />
            </div>
            <div className={styles.field}>
              <input
                className={styles.fieldInput}
                placeholder={"Enter Text Here..."}
                value={entry.param1}
                onChange={(e) =>
                  handleInputChange(e.target.value, "param1", entry)
                }
              />
            </div>
          </div>
          <div className={styles.paramItem}>
            <div className={styles.type}>Sub-Text</div>
            <div className={styles.iconContainer}>
              <img className={styles.icon} />
            </div>
            <div className={styles.field}>
              <input
                className={styles.fieldInput}
                placeholder={"Enter Text Here..."}
                value={entry.param2}
                onChange={(e) =>
                  handleInputChange(e.target.value, "param2", entry)
                }
              />
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className={styles.paramContainer}>
          <div className={styles.paramItem}>
            <div className={styles.type}>Text</div>
            <div className={styles.iconContainer}>
              <ImagePicker
                changeImageItem={(icon) => handleIconChange(icon, entry)}
                isADA={true}
                adaIMGSRC={entry.icon ? entry.icon : null}
              />
            </div>
            <div className={styles.field}>
              <input
                className={styles.fieldInput}
                placeholder={"Enter Text Here..."}
                value={entry.param1}
                onChange={(e) =>
                  handleInputChange(e.target.value, "param1", entry)
                }
              />
            </div>
          </div>
        </div>
      );
    }
  };

  // console.log(entries);
  return (
    <Card
      children={
        <section className={styles.container}>
          <div className={styles.title}>ADA</div>
          <hr className={styles.hr} />
          {entries.map((entry, index) => (
            <div key={index}>
              <div className={styles.entry}>
                <div className={styles.entryTitle}>
                  {capitalizeFirstLetter(entry.component)}
                </div>

                <div className={styles.entryParamsContainer}>
                  {renderParams(entry)}
                </div>

                <div className={styles.entryRemoveButtonContainer}>
                  {entry.component !== "description" && (
                    <Button
                      children="Remove"
                      className={styles.removeButton}
                      onClick={() => removeEntry(entry)}
                      size="small"
                    />
                  )}
                </div>
              </div>
              <hr className={styles.hr} />
            </div>
          ))}
          <AddButton
            description="Add another ADA Option"
            color="#9AB5BB"
            onClick={() => addNew()}
          />
        </section>
      }
    />
  );
}

ADAV2Form.propTypes = {
  /**Array of objects in the format:
   * [
   *    {
   *        question: "do you require x y z?",
   *        options: ["yes", "no", "etc"],
   *        id: 1 (unique id per question),
   *    }, and so on
   * ]
   */
  entries: PropTypes.arrayOf(PropTypes.shape({})),
  /**Function to handle setting the entries */
  setEntries: PropTypes.func,
};
