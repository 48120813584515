import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";

import EditTicketModal from "Events/Components/EditTicketModal";
import TicketGroupItem from "Events/Components/TicketGroupItem";
import Input from "shared/@forms/Input";
import Button from "shared/Button";
import { Select } from "shared/Select/Select";
import { Modal } from "shared/Modal";
import Checkbox from "shared/@forms/Checkbox";
import AddGroupModal from "../AddGroupModal";
import { GroupsGet } from "./GroupsGet";

import styles from "./TicketGroup.module.css";
import Loader from "shared/Loader";

import AddButton from "../AddButton";
import classNames from "classnames";

/**Displays the information for a ticket group and all the ticket options. The user can update the number of tickets available. If edit is clicked in the sub header, then the limit and tickets per will be editable with a select and input box.  */
export function TicketGroup({
  name,
  available,
  setAvailable,
  limit,
  setLimit,
  ticketsPer,
  setTicketsPer,
  tickets,
  updateTickets,
  eventStartDate,
  isMajorV1 = false,
  isV2,
  ticketSet,
  setTicketSet,
  isMajor,
}) {
  const {
    data: groupData,
    isLoading: isGroupsLoading,
    refetch: refetchGroupsGet,
  } = GroupsGet();

  const [showEditPurchaseLimits, setShowEditPurchaseLimits] = useState(false);
  const [ticketsPerField, setTicketsPerField] = useState("Tickets Per User");
  const [showEditModal, setShowEditModal] = useState(false);
  const [editingTicket, setEditingTicket] = useState({});
  const [groups, setGroups] = useState([]);
  const [selectedTicketId, setSelectedTicketId] = useState("");
  const [ticketsWithGroups, setTicketsWithGroups] = useState(
    ticketSet.tickets
      ? ticketSet.tickets.map((ticket) => ({
          ticketId: ticket.id,
          groups: ticket.groups || [], // Use existing groups if provided
        }))
      : []
  );
  const [isGroupModalOpen, setIsGroupModalOpen] = useState(false);
  const acceptedCreditOptions = [
    {
      creditTypeId: "LF",
      name: "Local Function Credit",
    },
    {
      creditTypeId: "MF",
      name: "Major Function Credit",
    },
    {
      creditTypeId: "FE25_FPL",
      name: "FE 2025 - Founders Platinum Credit",
    },
    {
      creditTypeId: "LS26_FPL",
      name: "LS 2026 - Founders Platinum Credit",
    },
    {
      creditTypeId: "FR26_FPL",
      name: "FE 2026 - Founders Platinum Credit",
    },
  ];
  const [isAcceptedCreditModalOpen, setIsAcceptedCreditModalOpen] =
    useState(false);
  const [acceptedCreditArray, setAcceptedCreditArray] = useState([]);

  useEffect(() => {
    if (groupData) {
      setGroups(groupData);
    }
  }, [groupData]);

  useEffect(() => {
    if (ticketSet.tickets && ticketsWithGroups.length == 0) {
      setTicketsWithGroups(
        ticketSet.tickets.map((ticket) => {
          return {
            ticketId: ticket.id,
            groups: [], //Should be changed to tickets.groups or whatever backend prop is storing which tickets have groups already selected
          };
        })
      );
    }
  }, [ticketSet]);

  const handleGroupSelection = (groupData) => {
    setTicketsWithGroups((prevTickets) => {
      return prevTickets.map((ticket) => {
        if (ticket.ticketId === selectedTicketId) {
          // Check if the group already exists in the array
          const groupExists = ticket.groups.some(
            (group) => group.id === groupData.id
          ); // Compare based on ID

          // Return a new object with updated groups
          return {
            ...ticket,
            groups: groupExists
              ? ticket.groups.filter((group) => group.id !== groupData.id) // Remove the group
              : [...ticket.groups, groupData], // Add the group
          };
        }

        // Return unchanged tickets
        return ticket;
      });
    });
  };

  const handleAddGroups = (newGroups) => {
    if (selectedTicketId === "ticketSet") {
      // Update the ticketSet's badges directly
      const updatedBadges = [
        ...ticketSet.badges,
        ...newGroups.filter(
          (newGroup) =>
            !ticketSet.badges.some(
              (existingBadge) => existingBadge.id === newGroup.id
            )
        ),
      ];

      // Update the ticketSet with the new badges
      setTicketSet({
        ...ticketSet,
        badges: updatedBadges,
      });
    } else {
      // Find the ticket to update
      const ticketToUpdate = ticketSet.tickets.find(
        (ticket) => ticket.id === selectedTicketId
      );

      if (ticketToUpdate) {
        // Combine existing groups with new groups, avoiding duplicates
        const updatedGroups = [
          ...ticketToUpdate.limitedToGroups,
          ...newGroups.filter(
            (newGroup) =>
              !ticketToUpdate.limitedToGroups.some(
                (existingGroup) => existingGroup.id === newGroup.id
              )
          ),
        ];

        // Update the ticket with the new groups
        const updatedTicket = {
          ...ticketToUpdate,
          limitedToGroups: updatedGroups,
        };

        // Replace the updated ticket in the ticket set
        const updatedTickets = ticketSet.tickets.map((ticket) =>
          ticket.id === selectedTicketId ? updatedTicket : ticket
        );

        // Update the ticket set state
        setTicketSet({
          ...ticketSet,
          tickets: updatedTickets,
        });
      }
    }
  };
  const handleRemoveGroup = (groupToRemove, targetId) => {
    if (targetId === "ticketSet") {
      // Remove the badge from the ticketSet badges array
      const updatedBadges = ticketSet.badges.filter(
        (badge) => badge.id !== groupToRemove.id
      );

      // Update the ticketSet with the new badges
      setTicketSet({
        ...ticketSet,
        badges: updatedBadges,
      });
    } else {
      // Find the ticket to update
      const ticketToUpdate = ticketSet.tickets.find(
        (ticket) => ticket.id === targetId
      );

      if (ticketToUpdate) {
        // Remove the group from the ticket's limitedToGroups array
        const updatedGroups = ticketToUpdate.limitedToGroups.filter(
          (group) => group.id !== groupToRemove.id
        );

        // Update the ticket with the new groups
        const updatedTicket = {
          ...ticketToUpdate,
          limitedToGroups: updatedGroups,
        };

        // Replace the updated ticket in the ticket set
        const updatedTickets = ticketSet.tickets.map((ticket) =>
          ticket.id === targetId ? updatedTicket : ticket
        );

        // Update the ticket set state
        setTicketSet({
          ...ticketSet,
          tickets: updatedTickets,
        });
      }
    }
  };
  const handleCheckBoxClick = (ticketId) => {
    if (ticketId === "ticketSet") {
      // Handle case if the entire ticketSet has a creditsRequired field (if applicable)
      setTicketSet({
        ...ticketSet,
        creditsRequired: !ticketSet.creditsRequired,
      });
    } else {
      const ticketToUpdate = ticketSet.tickets.find(
        (ticket) => ticket.id === ticketId
      );

      if (ticketToUpdate) {
        const updatedTicket = {
          ...ticketToUpdate,
          creditsRequired: !ticketToUpdate.creditsRequired,
        };

        const updatedTickets = ticketSet.tickets.map((ticket) =>
          ticket.id === ticketId ? updatedTicket : ticket
        );

        setTicketSet({
          ...ticketSet,
          tickets: updatedTickets,
        });
      }
    }
  };
  const handleSelectChange = (newValue, ticketId) => {
    const ticketToUpdate = ticketSet.tickets.find(
      (ticket) => ticket.id === ticketId
    );

    if (ticketToUpdate) {
      const updatedTicket = {
        ...ticketToUpdate,
        ticketId: newValue,
      };

      const updatedTickets = ticketSet.tickets.map((ticket) =>
        ticket.id === ticketId ? updatedTicket : ticket
      );

      setTicketSet({
        ...ticketSet,
        tickets: updatedTickets,
      });
    }
  };
  const handleCreditChange = () => {
    const ticketToUpdate = ticketSet.tickets.find(
      (ticket) => ticket.id === selectedTicketId
    );

    if (ticketToUpdate) {
      const updatedTicket = {
        ...ticketToUpdate,
        acceptedCreditTypes: acceptedCreditArray,
      };

      const updatedTickets = ticketSet.tickets.map((ticket) =>
        ticket.id === selectedTicketId ? updatedTicket : ticket
      );

      setTicketSet({
        ...ticketSet,
        tickets: updatedTickets,
      });
    }
  };

  const dateArray = eventStartDate;
  const eventDate = dayjs(dateArray);

  const handleEditOpen = (ticket) => {
    setEditingTicket({
      id: ticket.id,
      name: ticket.name,
      price: ticket.price.toString(),
      startDate: ticket.startDate,
      endDate: ticket.endDate,
      startTime: ticket.startTime,
      endTime: ticket.endTime,

      timeZone: ticket.timeZone,
    });

    setTimeout(() => {
      setShowEditModal(true);
    }, 0);
  };

  const handleEditClose = () => {
    setShowEditModal(false);
    setEditingTicket({});
  };

  const handleSaveEdit = (newTicket) => {
    // let startDate = parseStartDate(newTicket);
    // let endDate = parseEndDate(newTicket);

    const updatedTickets = tickets.map((ticket) => {
      if (ticket.id === newTicket.id) {
        return {
          ...ticket,
          name: newTicket.name,
          price: parseInt(newTicket.price),
          startDate: newTicket.startDate,
          startTime: newTicket.startTime,
          endDate: newTicket.endDate,
          endTime: newTicket.endTime,
          timeZone: newTicket.timeZone,
          // startDate: `${startDate.format("MM/DD/YYYY")}`,
          // endDate: `${endDate.format("MM/DD/YYYY")}`,
          //  startTime: `${newTicket.purchaseBegin.time}`,
          //  endTime: `${newTicket.purchaseEnd.time}`,
        };
      }
      return ticket;
    });

    updateTickets(updatedTickets);
    handleEditClose();
  };

  const parseStartDate = (newTicket) => {
    //   // eslint-disable-next-line default-case
    // switch (newTicket.purchaseBegin.length) {
    //   case "Days":
    //     return eventDate.subtract(
    //       parseInt(newTicket.purchaseBegin.period),
    //       "day"
    //     );
    //   case "Weeks":
    //     return eventDate.subtract(
    //       parseInt(newTicket.purchaseBegin.period),
    //       "week"
    //     );
    //   case "Months":
    //     return eventDate.subtract(
    //       parseInt(newTicket.purchaseBegin.period),
    //       "month"
    //     );
    // }
  };

  const parseEndDate = (newTicket) => {
    //   // eslint-disable-next-line default-case
    //   switch (newTicket.purchaseEnd.length) {
    //     case "Days":
    //       return eventDate.subtract(
    //         parseInt(newTicket.purchaseEnd.period),
    //         "day"
    //       );
    //     case "Weeks":
    //       return eventDate.subtract(
    //         parseInt(newTicket.purchaseEnd.period),
    //         "week"
    //       );
    //     case "Months":
    //       return eventDate.subtract(
    //         parseInt(newTicket.purchaseEnd.period),
    //         "month"
    //       );
    //   }
  };

  useEffect(() => {
    switch (limit) {
      case "Limit tickets per customer (IBO)":
        setTicketsPerField("Max Tickets Per Customer");
        break;
      default:
        setTicketsPerField("Tickets Per User");
        break;
    }
  }, [limit]);

  return (
    <>
      {isMajorV1 ? (
        // Layout for when isMajorV1 is true
        <div className={styles.ticketGroupContainer}>
          <div className={styles.header}>
            <span className={styles.label}>Ticket Set</span>
          </div>
          <div className={styles.body}>
            <div className={styles.ticketTableHeaderV1}>
              <span className={styles.ticketItem}>Ticket ID</span>
              <span>Ticket Name</span>
              <span className={styles.ticketPrice}>Price</span>
            </div>

            <div className={styles.ticketTableBody}>
              {tickets &&
                tickets?.map((ticket, index) => (
                  <div className={styles.ticketTableRow} key={index}>
                    <TicketGroupItem
                      ticket={ticket}
                      onEdit={handleEditOpen}
                      isMajorV1={isMajorV1}
                    />
                  </div>
                ))}
              <div className={styles.ticketTableRow}></div>
            </div>
          </div>
        </div>
      ) : isV2 ? (
        <div className={styles.ticketGroupContainer}>
          <div className={styles.header}>
            <span className={styles.label}>
              {ticketSet.ticketSet} Ticket Set
            </span>
          </div>
          <div className={styles.badgesContainer}>
            <div className={styles.speakerList}>
              {ticketSet.badges.map((group) => (
                <div className={styles.speakerItem}>
                  {group?.name || "No Name For Group"}
                  <div
                    className={styles.removeSpeaker}
                    onClick={() => handleRemoveGroup(group, "ticketSet")}
                  >
                    &times;
                  </div>
                </div>
              ))}
            </div>
            <div
              className={classNames(styles["button-container"])}
              onClick={() => {
                setSelectedTicketId("ticketSet");
                setIsGroupModalOpen(true);
              }}
            >
              <AddButton description={"Add Groups"} color="#2A3847" />
            </div>
          </div>
          <div className={styles.body}>
            <div className={styles.ticketTableHeaderV2}>
              <span>Name</span>
              <span className={styles.ticketPrice}>Price</span>
              <span>Ticket ID</span>
              <span>Groups</span>
              <span>Accepted Credit Types</span>
              <span>Credits Required</span>
            </div>
            <div className={styles.ticketTableBody}>
              {ticketSet.tickets && ticketsWithGroups.length > 0 ? (
                ticketSet?.tickets.map((ticket) => {
                  const ticketGroupData = ticketsWithGroups.find(
                    (twg) => twg.ticketId === ticket.id
                  );
                  return (
                    <TicketGroupItem
                      key={ticket.id}
                      ticket={ticket}
                      onEdit={handleEditOpen}
                      isMajorV1={false}
                      isV2={true}
                      onAddGroup={(ticketId) => {
                        setSelectedTicketId(ticketId);
                        setIsGroupModalOpen(true);
                      }}
                      selectedGroups={
                        ticketGroupData ? ticketGroupData.groups : []
                      }
                      onRemoveGroup={(group, ticketId) =>
                        handleRemoveGroup(group, ticketId)
                      }
                      onCheckbox={(ticketId) => handleCheckBoxClick(ticketId)}
                      onSelect={(newValue, ticketId) =>
                        handleSelectChange(newValue, ticketId)
                      }
                      onCreditChange={(ticketId) => {
                        setSelectedTicketId(ticketId);
                        setAcceptedCreditArray(
                          ticketSet.tickets.find((tkt) => tkt.id == ticketId)
                            .acceptedCreditTypes
                        );
                        setIsAcceptedCreditModalOpen(true);
                      }}
                      isMajor={isMajor}
                    />
                  );
                })
              ) : (
                <div>
                  <Loader />
                </div>
              )}
              <div className={styles.ticketTableRow}></div>
            </div>
          </div>
        </div>
      ) : (
        // Layout for when isMajorV1 is false
        <div className={styles.ticketGroupContainer}>
          <div className={styles.header}>
            <span className={styles.label}>Ticket Set</span>
            <span className={styles.name}>{name}</span>
            <span className={styles.ticketsAvailable}>Tickets Available</span>
            <span className={styles.ticketsAvailInput}>
              <span className={styles.numberSign}>#</span>
              <Input
                value={available}
                onChange={(e) => setAvailable(parseInt(e.target.value))}
                onFocus={(e) => e.target.select()}
              />
            </span>
          </div>
          <div className={styles.body}>
            {showEditPurchaseLimits ? (
              <div className={styles.purchaseEditContainer}>
                <span className={styles.label}>Purchase Limits</span>
                <span className={styles.purchaseLimitsSelect}>
                  <Select
                    options={[
                      "Limit tickets per each user",
                      "Limit tickets per customer (IBO)",
                    ]}
                    placeholder={limit}
                    onChange={(value) => setLimit(value)}
                  />
                </span>
                <span className={styles.ticketsPerUser}>{ticketsPerField}</span>
                <span className={styles.ticketsPerInput}>
                  <Input
                    value={ticketsPer}
                    onChange={(e) => setTicketsPer(e.target.value)}
                    placeholder="#"
                  />
                </span>

                <Button
                  size="small"
                  onClick={() => setShowEditPurchaseLimits(false)}
                  className={styles.editButton}
                >
                  Save
                </Button>
              </div>
            ) : (
              <div className={styles.purchaseEditContainer}>
                <span className={styles.label}>Purchase Limits</span>
                <span>{limit}</span>
                <span className={styles.ticketsPerUser}>{ticketsPerField}</span>
                <span className={styles.ticketsPerInput}>{ticketsPer}</span>

                <Button
                  size="small"
                  onClick={() => setShowEditPurchaseLimits(true)}
                  className={styles.editButton}
                >
                  Edit
                </Button>
              </div>
            )}
            <div className={styles.ticketTableHeader}>
              <span className={styles.ticketItem}>Ticket ID</span>
              <span>Ticket Name</span>
              <span className={styles.ticketPrice}>Price</span>
              <span>Purchase Begin</span>
              <span>Purchase End</span>
              <span>Time Zone</span>
              <span></span>
            </div>
            {editingTicket && (
              <div>
                <EditTicketModal
                  isOpen={showEditModal}
                  onCollapse={handleEditClose}
                  ticketInfo={editingTicket}
                  onClick={handleSaveEdit}
                />
              </div>
            )}

            <div className={styles.ticketTableBody}>
              {tickets?.map((ticket, index) => (
                <div className={styles.ticketTableRow} key={index}>
                  <TicketGroupItem
                    ticket={ticket}
                    onEdit={handleEditOpen}
                    isMajorV1={isMajorV1}
                    isV2={isV2}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {/* {isV2 && (
        <div className={styles.ticketGroupContainer}>
          <div className={styles.header}>
            <span className={styles.labelContainer}>
              <div className={styles.labelName}>
                Homesite Ticket Set: {homesiteSelected}
              </div>
              <div className={styles.addButton}>
                <Button
                  children={homesiteSelected ? "EDIT" : "ADD"}
                  onClick={() => setIsHomesiteModalOpen(true)}
                />
              </div>
            </span>
          </div>
          <div className={styles.body}>
            <div className={styles.ticketTableHeaderV1}>
              <span className={styles.ticketItem}>Ticket ID</span>
              <span>Ticket Name</span>
              <span className={styles.ticketPrice}>Price</span>
            </div>

            <div className={styles.ticketTableBody}>
              {tickets &&
                tickets?.map((ticket, index) => (
                  <div className={styles.ticketTableRow} key={index}>
                    <TicketGroupItem
                      ticket={ticket}
                      onEdit={handleEditOpen}
                      isMajorV1={isMajorV1}
                    />
                  </div>
                ))}
              <div className={styles.ticketTableRow}></div>
            </div>
          </div>
        </div>
      )} */}

      <AddGroupModal
        isOpen={isGroupModalOpen}
        onCollapse={() => setIsGroupModalOpen(false)}
        onClick={(newGroups) => handleAddGroups(newGroups)}
        groups={groups}
      />

      <Modal
        title="Accepted Credit Types"
        isOpen={isAcceptedCreditModalOpen}
        onClose={() => setIsAcceptedCreditModalOpen(false)}
        children={
          <div className={styles.modal}>
            <div className={styles.optionsListContainer}>
              <div className={styles.optionsListHeader}>
                <div className={styles.optionsHeader}>ID</div>
                <div className={styles.optionsHeader}>Credit Type</div>
              </div>
              <div className={styles.optionsListBody}>
                {acceptedCreditOptions.map((option) => (
                  <div className={styles.optionItemContainer}>
                    <div className={styles.optionItem}>
                      {option.creditTypeId}
                    </div>
                    <div className={styles.optionItem}>{option.name}</div>
                    <div className={styles.optionItem}>
                      <Checkbox
                        className={styles.optionCheckbox}
                        onClick={() => {
                          const isOptionSelected = acceptedCreditArray.some(
                            (credit) =>
                              credit.creditTypeId === option.creditTypeId
                          );
                          if (isOptionSelected) {
                            setAcceptedCreditArray(
                              acceptedCreditArray.filter(
                                (credit) =>
                                  credit.creditTypeId !== option.creditTypeId
                              )
                            );
                          } else {
                            setAcceptedCreditArray([
                              ...acceptedCreditArray,
                              option,
                            ]);
                          }
                        }}
                        checked={acceptedCreditArray.some(
                          (credit) =>
                            credit.creditTypeId === option.creditTypeId
                        )}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <Button
              className={styles.updateButton}
              children={"Update"}
              onClick={() => {
                handleCreditChange();
                setIsAcceptedCreditModalOpen(false);
              }}
            />
          </div>
        }
      />
    </>
  );
}

TicketGroup.propTypes = {
  /**Displays the name in the header  */
  name: PropTypes.string,
  /**Number of tickets available. A cumulative number for all the tickets within the group */
  available: PropTypes.number,
  /**The user can choose between limiting tickets per user or per customer.  */
  limit: PropTypes.string,
  /**Number of tickets allowed for purchase. This will change between Tickets per user (for limit tickets per each user) and Max tickets per customer (for limit tickets per customer (IBO)).  */
  ticketsPer: PropTypes.string,
  /**Array of ticket objects*/
  tickets: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      price: PropTypes.number,
      timeZone: PropTypes.string,
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      startTime: PropTypes.string,
      endTime: PropTypes.string,
    })
  ),
  /**Function to set the number of tickets that are available */
  setAvailable: PropTypes.func,
  /**Function to set the limit option */
  setLimit: PropTypes.func,
  /**Function to set the number of tickets allowed per purchase */
  setTicketsPer: PropTypes.func,
  /**Function that updates the ticket information */
  updateTickets: PropTypes.func,
  /**The date the event starts on */
  eventStartDate: PropTypes.string,
  isMajorV1: PropTypes.bool,
};
