import React, { useState } from "react";
import PropTypes from "prop-types";
import styles from "./NewEventNameModal.module.css";
import { Modal } from "shared/Modal";
import { ImagePicker } from "Events/Components/ImagePicker/ImagePicker";

import classNames from "classnames";

/**
 * Modal that opens when creating a new event name
 */
export function NewEventNameModal({ ruleset, isOpen, onCollapse, onSave }) {
  const [newEventName, setNewEventName] = useState("");
  const [image, setImage] = useState(null);

  const [errors, setErrors] = useState({
    newEventName: "",
    image: "",
  });

  function onClose() {
    setNewEventName("");
    setImage("");
    setErrors("");
    onCollapse();
  }

  const validateInput = () => {
    let tempErrors = {};

    if (!newEventName.trim() || newEventName.length < 0) {
      tempErrors.newEventName = "Please enter a new event name.";
    }

    if (!image) {
      tempErrors.image = "Please add and upload an image.";
    }

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleNewEventNameChange = (e) => {
    setNewEventName(e.target.value);
    // Clear the error if user type an event name
    if (errors.newEventName && e.target.value.trim()) {
      setErrors({ ...errors, newEventName: "" });
    }
  };

  const handleImageChange = (selectedImage) => {
    if (selectedImage) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const base64ImageData = event.target.result;
        setImage(base64ImageData);

        //Clear error if user uploaded an image
        if (errors.image) {
          setErrors({ ...errors, image: "" });
        }
      };
      reader.readAsDataURL(selectedImage);
    } else {
      setImage(null);
    }
  };

  const handleOnSave = () => {
    if (validateInput()) {
      onSave(newEventName, image);
      onClose();
    }
  };

  return (
    <>
      <Modal
        title="CREATE NEW EVENT NAME"
        isOpen={isOpen}
        onClose={onClose}
        // closeBtn=""
        className={styles.modal}
        children={
          <>
            <div className={classNames(styles.grid)}>
              <div className={classNames(styles.bold, styles.ruleset)}>
                Ruleset
              </div>
              <div>{ruleset}</div>

              <div className={styles.bold}>Event Name</div>
              <input
                className={styles.input}
                placeholder="Click to type"
                value={newEventName}
                onChange={handleNewEventNameChange}
              />
            </div>

            <div className={styles.errorContainer}>
              {errors.newEventName && (
                <span className={styles.errorOrgMsg}>
                  {errors.newEventName}
                </span>
              )}

              {errors.image && (
                <span className={styles.errorOrgMsg}>{errors.image}</span>
              )}
            </div>

            <ImagePicker changeImageItem={handleImageChange} />

            <div>
              <button
                className={classNames(styles.button)}
                onClick={handleOnSave}
              >
                SAVE
              </button>
            </div>
          </>
        }
      />
    </>
  );
}

NewEventNameModal.propTypes = {
  ruleset: PropTypes.string,
  isOpen: PropTypes.bool,
  onCollapse: PropTypes.func,
  onSave: PropTypes.func,
};
