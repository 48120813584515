import PropTypes from "prop-types";
import styles from "./HomesiteRow.module.css";
import classNames from "classnames";
import { capitalizeCityName } from "shared/@utils/capitalizeCityName";

/**
 * Displays the information of a past homesite as well as an edit button.
 */
export function HomesiteRow({ Homesite, onClick }) {
  return (
    <div className={styles.container}>
      <div className={styles.gridItem}>{Homesite.name}</div>
      <div className={styles.gridItem}>{Homesite.code}</div>
      <div className={styles.gridItem}>{Homesite.location}</div>
      <div className={classNames(styles.gridItem, styles.noMargin)}>
        {`${capitalizeCityName(Homesite.city)}`}
      </div>

      <div className={classNames(styles.gridItem, styles.noMargin)}>
        {Homesite.state}
      </div>
      <div className={classNames(styles.gridItem, styles.noMargin)}>
        {Homesite.country}
      </div>
      <div className={`${styles.editFlexItem}`}>
        <button className={styles.button} onClick={onClick}>
          Edit
        </button>
      </div>
    </div>
  );
}

HomesiteRow.propTypes = {
  Homesite: PropTypes.object,
  onClick: PropTypes.func,
};
