import React from "react";
import PropTypes from "prop-types";
import Checkbox from "shared/@forms/Checkbox";
import { Link } from "react-router-dom";
import styles from "./GroupItem.module.css";
import classNames from "classnames";

/**
 * Displays the information of a speaker and the ability to select them.
 */
export function GroupItem({ group, onSelect }) {
  return (
    <tr className={styles.speakerItemRow}>
      <td className={styles.checkbox}>
        <Checkbox onChange={() => onSelect(group)} />
      </td>

      <td className={classNames(styles.item, styles.name)}>{group.name}</td>

      <td className={classNames(styles.item, styles.type)}>{group.type}</td>
      <td className={classNames(styles.item, styles.description)}>
        {group.description}
      </td>
    </tr>
  );
}

GroupItem.propTypes = {
  /**
   * Object with the group's information.
   */
  group: PropTypes.object,
  /**
   * Function called when the group is selected
   */
  onSelect: PropTypes.func,
};
