import React from "react";
import PropTypes from "prop-types";

import Button from "shared/Button";
import AddButton from "../AddButton";
import Checkbox from "shared/@forms/Checkbox";
import Select from "../Select";

import styles from "./TicketGroupItem.module.css";
import classNames from "classnames";

/**Displays the information about a ticket option. When edit is clicked it will open the edit ticket modal. */
export function TicketGroupItem({
  ticket,
  onEdit,
  isMajorV1,
  isV2,
  onAddGroup,
  selectedGroups,
  onRemoveGroup,
  onCheckbox,
  onSelect,
  onCreditChange,
  isMajor,
}) {
  return isMajorV1 ? (
    <div className={styles.ticketGroupItemContainerV1}>
      <span>{ticket.ticketId}</span>
      <span>{ticket.name}</span>
      <span className={styles.price}>{` $${ticket.price} USD`}</span>{" "}
      <span className={styles.price}>{ticket.cancelPrice}</span>
      {/* <Button
        size="small"
        className={styles.editButton}
        onClick={() => onEdit(ticket)}
      >
        Edit
      </Button> */}
    </div>
  ) : isV2 ? (
    <div
      className={
        isMajor
          ? styles.ticketGroupItemContainerV2Dropdown
          : styles.ticketGroupItemContainerV2
      }
    >
      <span>{ticket.name}</span>
      <span>{` $${ticket.price} USD`}</span>
      <span>
        {isMajor ? (
          <div className={styles.selectContainer}>
            <Select
              options={[
                `FE${ticket?.ticketId.slice(2)}`,
                `LS${ticket?.ticketId.slice(2)}`,
              ]}
              placeholder={ticket?.ticketId}
              onChange={(newValue) => onSelect(newValue, ticket.id)}
              removeSelectedOption={false}
            />
          </div>
        ) : (
          ticket.ticketId
        )}
      </span>
      <span className={styles.groups}>
        <div className={styles.groupsList}>
          {ticket.limitedToGroups.map((group) => (
            <div className={styles.groupItem}>
              {group?.name || "No Name For Group"}
              <div
                className={styles.removeGroup}
                onClick={() => onRemoveGroup(group, ticket.id)}
              >
                &times;
              </div>
            </div>
          ))}
        </div>
        <div
          className={classNames(styles["button-container"])}
          onClick={() => onAddGroup(ticket.id)}
        >
          <AddButton description={"Add Groups"} color="#2A3847" />
        </div>
      </span>

      <span className={styles.creditTypes}>
        {ticket.acceptedCreditTypes.map((type) => (
          <span key={type.id}>{type.name}</span>
        ))}
        <Button
          className={styles.addGroupButton}
          children={"Edit"}
          onClick={() => onCreditChange(ticket.id)}
        />
      </span>
      <span className={styles.checkbox}>
        <Checkbox
          onClick={() => onCheckbox(ticket.id)}
          checked={ticket.creditsRequired}
        />
      </span>
    </div>
  ) : (
    <div className={styles.ticketGroupItemContainer}>
      <span>{ticket.id}</span>
      <span>{ticket.name}</span>
      <span className={styles.price}>{` $${ticket.price} USD`}</span>{" "}
      <span>{`${ticket.startDate} ${ticket.startTime}`}</span>
      <span>{`${ticket.endDate} ${ticket.endTime}`}</span>
      <span className={styles.timezone}>{ticket.timeZone}</span>
      <Button
        size="small"
        className={styles.editButton}
        onClick={() => onEdit(ticket)}
      >
        Edit
      </Button>
    </div>
  );
}

TicketGroupItem.propTypes = {
  /**Object with the tickets info (id-String, name-String, price-Number, timeZone-String, startDate-String, endDate-String, startTime-String, endTime-String)  */
  ticket: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    price: PropTypes.number,
    timeZone: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    startTime: PropTypes.string,
    endTime: PropTypes.string,
  }),
  /**Function called to edit the ticket information. Opens the edit ticket modal */
  onEdit: PropTypes.func,
  isMajorV1: PropTypes.bool,
};
