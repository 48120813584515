import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import FilterVenues from "../../Components/FilterVenues";
import CreateNewCard from "Events/Components/CreateNewCard";
import CreateNewVenueModal from "Events/Components/CreateNewVenueModal";
import VenueList from "Events/Components/VenueList";
import Loader from "shared/Loader";
import PagingControls from "shared/PagingControls";
import styles from "./ManageVenuesPage.module.css";
import { useManageVenuesPage, useVenueOptionsGet } from "./useManageVenuesPage";
import { useCurrentRoute } from "shared/@hooks/useCurrentRoute";
import { capitalizeCityName } from "shared/@utils/capitalizeCityName";
import ComparisonModal from "Events/Components/ComparisonModal";

// A page under events page in options to manage add and edit the venues.
export function ManageVenuesPage() {
  const { data: states, isLoading: isLoadingStates } = useCurrentRoute();

  const [venueParams, setVenueParams] = useState({
    ruleset: "all",
    stateId: "all",
    city: "all",
    search: "none",
    sort: "name-asc",
    page: 1,
    limit: 15,
  });

  const { data, isLoading, refetch, resolveAddress, createVenue } =
    useManageVenuesPage(venueParams);
  const { data: venueOptionsData, isLoading: isVenueOptionsDataLoading } =
    useVenueOptionsGet("all");

  const [fetchedVenues, setFetchedVenues] = useState([]);
  const [filters, setFilters] = useState({
    ruleset: "all",
    stateId: "all",
    city: "all",
    search: "none",
    sort: "name-asc",
    limit: 15,
    lastPage: 32,
    page: 1,
    total: 473,
  });
  const [doRefetch, setDoRefetch] = useState(false);

  useEffect(() => {
    if (data) {
      setFetchedVenues(data.data);
      setFilters(data.filters);
    }
  }, [data]);

  useEffect(() => {
    if (doRefetch) {
      refetch().then(() => setDoRefetch(false));
    }
  }, [venueParams]);

  const changeParams = (paramToChange, changedData) => {
    setVenueParams((prevParams) => ({
      ...prevParams,
      [paramToChange]: changedData,
    }));
    setDoRefetch(true);
  };

  const [isLoadingVenues, setIsLoadingVenues] = useState(isLoading);
  useEffect(() => {
    setIsLoadingVenues(isLoading);
  }, [isLoading]);

  const [stateIdToNameMap, setStateIdToNameMap] = useState({});
  const [stateNameToIdMap, setStateNameToIdMap] = useState({});
  const [showCreateNew, setShowCreateNew] = useState(false);
  const [originalVenues, setOriginalVenues] = useState([]);
  const [venues, setVenues] = useState([]);
  const [selectOptions, setSelectOptions] = useState({});
  const [rulesetOptions, setRulesetOptions] = useState([]);
  const [rulesets, setRulesets] = useState([]);
  useEffect(() => {
    if (venueOptionsData) {
      // const names = rulesetData.map((item) => item.name);
      setRulesets(venueOptionsData.rulesets);
    }
  }, [venueOptionsData]);
  const [sort, setSort] = useState("");
  const [isCompLoading, setIsCompLoading] = useState(false);
  const [comparisonModalOpen, setComparisonModalOpen] = useState(false);
  const [suggestedAddress, setSuggestedAddress] = useState({
    line1: "",
    line2: "",
    city: "",
    state: "",
    country: "",
    postalCode: "",
  });
  const [suggestions, setSuggestions] = useState([]);
  useEffect(() => {
    if (suggestions.length > 0) {
      setComparisonModalOpen(false);
      setShowCreateNew(true);
    }
  }, [suggestions]);
  const [formData, setFormData] = useState({
    companyId: 0,
    ruleset: "",
    name: "",
    line1: "",
    line2: "",
    city: "",
    state: "", //StateName - is transformed to StateId when being passed
    country: "", //CountryName - ||
    postalCode: "",
    latitude: null,
    longitude: null,
    phone: "",
    fax: "",
    url: "",
    rooms: [{ name: "", capacity: "" }],
    isVerified: false,

    email: "", // Not used in V1 Events - hide when ruleset selected
  });

  useEffect(() => {
    if (sort && filters.sort !== sort) {
      changeParams("sort", sort);
    }
  }, [sort]);

  useEffect(() => {
    if (filters.sort) {
      setSort(filters.sort);
    }
  }, [filters]);

  useEffect(() => {
    if (venueOptionsData && states) {
      const newStateIdToNameMap = states.reduce((acc, state) => {
        acc[state.stateId.toString()] = state.state;
        return acc;
      }, {});

      const newStateNameToIdMap = states.reduce((acc, state) => {
        acc[state.state] = state.stateId.toString();
        return acc;
      }, {});

      setStateIdToNameMap(newStateIdToNameMap);
      setStateNameToIdMap(newStateNameToIdMap);

      let statesAndCities = {};

      // Process the locations from venueOptionsData
      venueOptionsData.locations.forEach((location) => {
        const stateName =
          newStateIdToNameMap[location.stateId] || "Unknown State";

        // Initialize the state if it doesn't exist, with "All"
        if (!statesAndCities[stateName]) {
          statesAndCities[stateName] = ["All"];
        }

        const formattedCity = capitalizeCityName(location.city);

        // Add city to the state if it's not already included
        if (!statesAndCities[stateName].includes(formattedCity)) {
          statesAndCities[stateName].push(formattedCity);
        }
      });

      // Ensure all cities lists are sorted, keeping "All" at the start
      Object.keys(statesAndCities).forEach((state) => {
        const cities = statesAndCities[state]
          .filter((city) => city !== "All")
          .sort();
        statesAndCities[state] = ["All", ...cities]; // Ensure "All" remains at the start
      });

      // Set ruleset options and select options
      setRulesetOptions([...new Set(venueOptionsData.rulesets)].sort());
      setSelectOptions(statesAndCities);
    }
  }, [venueOptionsData, states]);

  useEffect(() => {
    if (!isLoadingStates && states && !isLoading && fetchedVenues) {
      const transformedAndFilteredVenues = fetchedVenues
        .filter((venue) => venue.venueName)
        .map((venue) => ({
          companyId: venue.venueId,
          name: venue.venueName, // || venue.name,
          street: venue.street1,
          city: venue.city,
          state: venue.stateId?.toString(),
          zip: venue.zip,
          phone: venue.phone,
          url: venue.URL,
          fax: venue.fax,
          rooms: venue.rooms.map((room) => ({
            name: room.roomName,
            capacity: room.capacity?.toString(),
            roomId: room.roomId,
          })),
          ruleset: venue.ruleset,
        }));

      setOriginalVenues(transformedAndFilteredVenues);
      setVenues(transformedAndFilteredVenues);
    }
  }, [fetchedVenues, isLoading, states, isLoadingStates]);

  const handleCreateNew = () => {
    setShowCreateNew(true);
  };

  const addNewVenue = async (formData) => {
    setIsCompLoading(true);
    setFormData(formData);
    setShowCreateNew(false);
    setComparisonModalOpen(true);

    const requiredFields = [
      { field: formData.ruleset, errorMessage: "Ruleset Not Selected" },
      { field: formData.name, errorMessage: "Venue Name Missing" },
      { field: formData.line1, errorMessage: "Address 1 Missing" },
      { field: formData.city, errorMessage: "City Missing" },
      {
        field: formData.state,
        errorMessage: "State/Province/Parish Not Selected",
      },
      { field: formData.country, errorMessage: "Country Not Selected" },
      { field: formData.postalCode, errorMessage: "Zip/Postal Code Missing" },
    ];

    let newSuggestions = [];

    requiredFields.forEach(({ field, errorMessage }) => {
      if (!field) {
        newSuggestions.push(errorMessage);
      }
    });

    if (newSuggestions.length > 0) {
      setSuggestions(newSuggestions);
      setComparisonModalOpen(false);
      setShowCreateNew(true);
      return;
    }

    const resolveAddressData = {
      line1: formData.line1,
      line2: formData.line2,
      city: formData.city,
      state: formData.state, //Should be passed in as name
      country: formData.country, //||
      postalCode: formData.postalCode,
    };

    try {
      const returnAddress = await resolveAddress.mutateAsync(
        resolveAddressData
      );

      if (returnAddress?.suggestions?.length > 0) {
        setSuggestions(returnAddress.suggestions);
      } else {
        setSuggestedAddress(returnAddress);
        setSuggestions([]);
      }
      setIsCompLoading(false);
    } catch (error) {
      console.error("Error Resolving Address:", error);
      setIsCompLoading(false);
    }
  };

  const handleUseSuggested = () => {
    const newFormData = {
      ...formData,
      line1: suggestedAddress.line1,
      line2: suggestedAddress.line2,
      city: suggestedAddress.city,
      state: suggestedAddress.state,
      postalCode: suggestedAddress.postalCode,
      country: suggestedAddress.country,
      latitude: suggestedAddress.latitude,
      longitude: suggestedAddress.longitude,
      isVerified: true,
    };
    setFormData(newFormData);
    submitVenue(newFormData);
  };

  const handleUseOriginal = () => {
    const newFormData = {
      ...formData,
      latitude: suggestedAddress.latitude,
      longitude: suggestedAddress.longitude,
      isVerified: false,
    };
    setFormData(newFormData);
    submitVenue(newFormData);
  };

  const submitVenue = async (formDataToSubmit) => {
    setComparisonModalOpen(false);

    const { rooms, ...otherFormData } = formDataToSubmit;
    let filteredRooms = rooms;

    if (
      rooms &&
      rooms.length === 1 &&
      rooms[0].name === "" &&
      rooms[0].capacity === ""
    ) {
      filteredRooms = undefined;
    }
    let selectedStateObject;
    if (formDataToSubmit.state.length == 2) {
      selectedStateObject = states.find(
        (state) =>
          state.abbreviation.toLowerCase() ==
          formDataToSubmit.state.toLowerCase()
      );
    } else {
      selectedStateObject = states.find(
        (state) =>
          state.state.toLowerCase() == formDataToSubmit.state.toLowerCase()
      );
    }
    const stateId = selectedStateObject.stateId;
    const countryId = selectedStateObject.countryId;
    const newVenue = {
      // companyId: companyId,
      ruleset: formDataToSubmit.ruleset,
      name: formDataToSubmit.name,
      line1: formDataToSubmit.line1,
      line2: formDataToSubmit.line2 || "",
      city: formDataToSubmit.city,
      stateId: stateId,
      countryId: countryId,
      postalCode: formDataToSubmit.postalCode,
      latitude: formDataToSubmit.latitude,
      longitude: formDataToSubmit.longitude,
      phone: formDataToSubmit.phone,
      url: formDataToSubmit.url,
      fax: formDataToSubmit.fax,
      ...(filteredRooms ? { rooms: formDataToSubmit.rooms } : {}),

      ...(!formDataToSubmit.ruleset.toLowerCase().includes("v1.0")
        ? { email: formDataToSubmit.email }
        : {}),
      
      isVerified: formDataToSubmit.isVerified,
    };

    try {
      await createVenue.mutateAsync(newVenue).then(() => {
        refetch().then(() => setIsLoadingVenues(false));
      });
      setFormData({
        companyId: 0,
        ruleset: "",
        name: "",
        line1: "",
        line2: "",
        city: "",
        state: "",
        country: "",
        postalCode: "",
        latitude: null,
        longitude: null,
        phone: "",
        fax: "",
        url: "",
        rooms: [{ name: "", capacity: "" }],

        email: "",
      });
    } catch (error) {
      console.error("Error Creating Venue:", error);
    }

    // setOriginalVenues((prev) => [...prev, newVenue]);
    // setVenues((prev) => [...prev, newVenue]);
    // setShowCreateNew(false);
  };

  const handleFiltering = (filter) => {
    if (filter.state && filter.state !== "All") {
      const stateId = stateNameToIdMap[filter.state];
      if (!isNaN(stateId)) {
        changeParams("stateId", stateId);
      } else {
        changeParams("stateId", "all");
      }
    } else {
      changeParams("stateId", "all");
    }

    if (filter.city && filter.city !== "All") {
      changeParams("city", filter.city);
    } else {
      changeParams("city", "all");
    }

    const ruleset = filter.ruleset && filter.ruleset.toLowerCase();
    if (ruleset.includes("2")) {
      changeParams("ruleset", "v2");
    } else if (ruleset.includes("major")) {
      changeParams("ruleset", "major");
    } else if (ruleset.includes("local")) {
      changeParams("ruleset", "local");
    } else {
      changeParams("ruleset", "all");
    }

    if (filter.search && filter.search.trim() !== "") {
      changeParams("search", filter.search.trim());
    } else {
      changeParams("search", "none");
    }

    setDoRefetch(true);
  };

  return (
    <div className={styles.container}>
      {isLoading || isLoadingStates ? (
        <>
          <div className={styles.loader}>
            <div>
              <Loader />
            </div>
            <div className={styles.loaderText}>Loading Venues </div>
          </div>
        </>
      ) : (
        <>
          <div className={styles.headerContainer}>
            <div>
              <span className={styles.backButton}>
                <Link to="/events/events-details">{"< Back"}</Link>
              </span>
              <h1 className={styles.pageTitle}>MANAGE VENUES</h1>
            </div>
            <div className={styles.venueControls}>
              <FilterVenues
                onClick={handleFiltering}
                locations={selectOptions}
                rulesetOptions={rulesetOptions}
                filters={filters}
                stateIdToNameMap={stateIdToNameMap}
              />

              <span className={styles.createNewCard}>
                <CreateNewCard
                  title="ADD VENUE"
                  onClick={() => handleCreateNew()}
                />
              </span>
            </div>
          </div>

          {isLoading || isLoadingStates ? (
            <Loader />
          ) : (
            <div className={styles.venueListContainer}>
              <PagingControls
                page={filters.page}
                lastPage={filters.lastPage}
                limit={filters.limit}
                limitOptions={[15, 25, 50]}
                onPageChange={(value) => changeParams("page", value)}
                onLimitChange={(value) => changeParams("limit", value)}
                disableNext={filters.page == filters.lastPage}
                disabled={isLoading}
              />
              <div className={styles.venueList}>
                <VenueList
                  venueList={venues}
                  sort={sort}
                  setSort={setSort}
                  isVenuesLoading={isLoadingVenues}
                  refetchVenues={refetch}
                />
              </div>
            </div>
          )}
        </>
      )}

      <div>
        <CreateNewVenueModal
          isOpen={showCreateNew}
          onCollapse={() => {
            setShowCreateNew(false);
            setSuggestions([]);
          }}
          onClick={addNewVenue}
          initialData={formData}
          states={states}
          rulesets={rulesets}
          errorsList={suggestions}
        />
      </div>

      <div>
        <ComparisonModal
          suggestedAddress={suggestedAddress}
          address={formData}
          handleUseSuggested={() => handleUseSuggested()}
          handleUseOriginal={() => handleUseOriginal()}
          handleEditClick={() => {
            setSuggestions([]);
            setComparisonModalOpen(false);
            setShowCreateNew(true);
          }}
          onClose={() => setComparisonModalOpen(false)}
          isOpen={comparisonModalOpen}
          title={"Confirm Address"}
          loading={isCompLoading}
          // isAdd={}
        />
      </div>
    </div>
  );
}
