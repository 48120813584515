import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Card from "shared/Card";
import TicketGroup from "Events/Components/TicketGroup";
import styles from "./TicketingV2Form.module.css";

/**Area to fill out ticketing information  */
export function TicketingV2Form({ ticketSets, setTicketSets, isVirtual, isMajor }) {
  const handleSetTicketSet = (updatedTicketSet) => {
    const updatedTicketSets = ticketSets.map((ticketSet) =>
      ticketSet.id === updatedTicketSet.id ? updatedTicketSet : ticketSet
    );
    setTicketSets(updatedTicketSets);
  };
  return (
    <Card
      children={
        <section>
          <div className={styles.bold}>TICKETING</div>
          <hr className={styles.hr} />
          <div className={styles.ticketGroup}>
            {ticketSets
              .filter((ticketSet) => {
                // If virtual, keep only "Virtual"; otherwise, remove "Virtual"
                return isVirtual
                  ? ticketSet.ticketSet === "Virtual"
                  : ticketSet.ticketSet !== "Virtual";
              })
              .map((ticketSet, index) => {
                return (
                  <TicketGroup
                    key={index}
                    ticketSet={ticketSet}
                    setTicketSet={(newData) => handleSetTicketSet(newData)}
                    isMajorV1={false}
                    isV2={true}
                    isMajor={isMajor}
                  />
                );
              })}
          </div>
        </section>
      }
    />
  );
}

TicketingV2Form.propTypes = {
  /**Array of ticket group objects (name-String, available-Number, limit-String, ticketsPer-string, tickets-Array)  */
  ticketSets: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      available: PropTypes.number,
      setAvailable: PropTypes.func,
      limit: PropTypes.string,
      setLimit: PropTypes.shape({}),
      ticketsPer: PropTypes.string,
      setTicketsPer: PropTypes.shape({}),
      tickets: PropTypes.array,
      updateTickets: PropTypes.shape({}),
      eventStartDate: PropTypes.string,
    })
  ),
  /**Function that changes the ticket groups */
  setTicketGroups: PropTypes.func,
};
