import styles from "./CreateNewHomesiteModal.module.css";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";

import { AddButton } from "Events/Components/AddButton/AddButton";
import { InputItem } from "../InputItem/InputItem";
import { Button } from "../../../shared/Button/Button";
import ModalActionBar from "../../../shared/ModalActionBar";
import { EditSelect } from "../EditSelect/EditSelect";
import Select from "../Select";
import { Modal } from "../../../shared/Modal";
import { RadioButtonList } from "../RadioButtonList/RadioButtonList";
import { useCurrentRoute } from "shared/@hooks/useCurrentRoute";

export function CreateNewHomesiteModal({
  isOpen,
  onCollapse,
  onClick,
  initialData,
  errorsList,
}) {
  useEffect(() => {
    if (isOpen && errorsList.length > 0) {
      const modalContainer = document.querySelector(`.${styles.modal}`);
      if (modalContainer) {
        modalContainer.scrollTo({ top: 0, behavior: "smooth" });
      }
    }
  }, [isOpen, errorsList]);

  const { data: statesData, isLoading: isLoadingStates } = useCurrentRoute();

  const countryIdName = {
    220: "U.S.",
    38: "CDN",
    40: "JAMAICA",
    221: "JAMAICA",
    300: "ANZ",
    151: "ANZ",
  };

  const getCountryNameFromState = (state) => {
    let foundState = statesData.find((item) => item.state == state);
    if (!foundState) {
      foundState = statesData.find((item) => item.stateId == state);
    }

    const id = foundState ? foundState.countryId : 220;

    return countryIdName[id];
  };

  const [newName, setNewName] = useState("");
  const [newCode, setNewCode] = useState("");
  const [newLocation, setNewLocation] = useState("");
  const [newCity, setNewCity] = useState("");
  const [newStreet1, setNewStreet1] = useState("");
  const [newStreet2, setNewStreet2] = useState("");
  const [newZip, setNewZip] = useState("");
  const [newState, setNewState] = useState("");
  const [newCountry, setNewCountry] = useState("");
  const [states, setStates] = useState([]);

  const handleFormSubmittal = () => {
    const formData = {
      regionId: initialData.regionId || "",
      name: newName,
      code: newCode,
      location: newLocation,
      city: newCity,
      line1: newStreet1,
      line2: newStreet2,
      postalCode: newZip,
      state: newState,
      country: newCountry,
    };
    onClick(formData);
  };

  useEffect(() => {
    if (statesData) {
      const stateNames = statesData.map((state) => state.state);
      setStates(stateNames.filter((state) => state !== "N/A"));
    }
  }, [statesData]);

  useEffect(() => {
    if (initialData) {
      setNewName(initialData.name ?? "");
      setNewCode(initialData.code ?? "");
      setNewLocation(initialData.location ?? "");
      setNewCity(initialData.city ?? "");
      setNewStreet1(initialData.line1 ?? "");
      setNewStreet2(initialData.line2 ?? "");
      setNewZip(initialData.postalCode ?? "");
      setNewState(initialData.state ?? "");
      setNewCountry(getCountryNameFromState(initialData.country));
    }
  }, [initialData]);

  useEffect(() => {
    if (initialData.state && statesData) {
      const selectedState = statesData.find(
        (item) => item.abbreviation.trim() === initialData.state.trim()
      );
      setNewState(selectedState ? selectedState.state : "");
    }
  }, [initialData, statesData]);

  const handleStateChange = (value) => {
    setNewState(value.split(" - ")[1]); //Update to have all cases match when api hookup is done
    const stateWithId = statesData.find(
      (item) => item.state.toString() === value.split(" - ")[1]
    );
    setNewState(stateWithId.state);
  };

  const handleCountrySelect = (country) => {
    setNewCountry(country);
  };

  const getLabelForCountry = (country) => {
    switch (country) {
      case "CDN":
        return "Province*";
      case "JAMAICA":
        return "Parish*";
      default:
        return "State*";
    }
  };
  const getPostalLabelForCountry = (country) => {
    switch (country) {
      case "CDN":
        return "Postal Code*";
      case "JAMAICA":
        return "Postal Code*";
      default:
        return "ZIP Code*";
    }
  };

  const handleStateSelection = (value) => {
    setNewState(value);
  };

  const [stateOptions, setStateOptions] = useState([]);

  useEffect(() => {
    if (statesData) {
      const matchingCountryIds = Object.keys(countryIdName).filter(
        (key) => countryIdName[key] === newCountry
      );

      if (matchingCountryIds.length > 0) {
        const filteredStates = statesData
          .filter((state) =>
            matchingCountryIds.includes(state.countryId.toString())
          )
          .filter((state) => state.state !== "N/A")
          .map((state) => `${state.abbreviation} - ${state.state}`);

        //setStateOptions if they have changed,
        setStateOptions((prevStateOptions) => {
          const newOptions = filteredStates;
          return prevStateOptions.join() !== newOptions.join()
            ? newOptions
            : prevStateOptions;
        });
      } else {
        setStateOptions([]);
      }
    }
  }, [statesData, newCountry]);

  const renderCountryField = () => {
    // Sequence of countries
    if (statesData) {
      const countrySequence = ["U.S.", "CDN", "ANZ", "JAMAICA"];

      const existingCountry = new Set(
        statesData.map((state) => state.countryId)
      );

      // Filter valid country options
      const countryOptions = countrySequence.filter((countryName) =>
        Object.entries(countryIdName).some(
          ([id, name]) =>
            name === countryName && existingCountry.has(parseInt(id))
        )
      );

      return (
        <span className={styles.radioButtonList}>
          <RadioButtonList
            onClick={handleCountrySelect}
            options={countryOptions}
            defaultValue={newCountry}
            groupName="homesiteCountry"
          />
        </span>
      );
    }
  };

  const renderStateSelection = () => {
    // const stateValue =
    //   eventData?.state?.value === 0 || eventData?.state === 0
    //     ? "N/A"
    //     : eventData?.state?.value || eventData?.state?.saved;

    // const hasStateError = validationErrors["state"];

    // Display state in words, don't display if value is "0"
    const getStateNameById = (id) => {
      if (id === "0") return "N/A";

      const state = statesData?.find(
        (state) => state?.stateId?.toString() === id?.toString()
      );

      if (state) {
        return `${state.abbreviation} - ${state.state}`;
      } else {
        return "N/A";
      }
    };

    return (
      <div className={styles.stateContainer}>
        <EditSelect
          label={getLabelForCountry(newCountry) || "State*"}
          options={stateOptions}
          placeholder={newState ? newState : "Select"}
          onChange={(value) => handleStateChange(value)}
          className={styles.selectDropdown}
        />
      </div>
    );
  };
  return (
    <Modal
      title="EDIT HOMESITE"
      isOpen={isOpen}
      onClose={onCollapse}
      className={styles.modal}
      children={
        <>
          {errorsList.length > 0 && (
            <div className={styles.errorsContainer}>
              {errorsList.map((error) => (
                <div className={styles.error}>{error}</div>
              ))}
            </div>
          )}
          <div className={styles.container}>
            <div className={styles.section}>
              <div className={styles.inputRow}>
                <InputItem
                  label="Homesite Name*"
                  placeholder=""
                  value={newName}
                  onChange={(e) => {
                    setNewName(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className={styles.section}>
              <div className={styles.inputRow}>
                <InputItem
                  label="Code*"
                  placeholder=""
                  value={newCode}
                  onChange={(e) => {
                    setNewCode(e.target.value);
                  }}
                />
              </div>
              <div className={styles.inputRow}>
                <InputItem
                  label="Location*"
                  placeholder=""
                  value={newLocation}
                  onChange={(e) => {
                    setNewLocation(e.target.value);
                  }}
                />
              </div>
              <div className={styles.inputRow}>
                <InputItem
                  label="Street 1*"
                  placeholder=""
                  value={newStreet1}
                  onChange={(e) => {
                    setNewStreet1(e.target.value);
                  }}
                />
              </div>
              <div className={styles.inputRow}>
                <InputItem
                  label="Street 2*"
                  placeholder=""
                  value={newStreet2}
                  onChange={(e) => {
                    setNewStreet2(e.target.value);
                  }}
                />
              </div>
              <div className={styles.radioCol}>
                <span className={styles.label}>Country* </span>
                {renderCountryField()}
              </div>
              <div className={styles.inputRow}>
                <InputItem
                  label="City*"
                  placeholder=""
                  value={newCity}
                  onChange={(e) => {
                    setNewCity(e.target.value);
                  }}
                />
              </div>
              <div className={styles.inputRow}>{renderStateSelection()}</div>
              <div className={styles.inputRow}>
                <InputItem
                  label={getPostalLabelForCountry(newCountry) || "Zip*"}
                  placeholder=""
                  value={newZip}
                  onChange={(e) => {
                    setNewZip(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className={styles.saveBtn}>
              <ModalActionBar
                children={
                  <>
                    <Button
                      className={styles.update}
                      children="UPDATE"
                      onClick={() => handleFormSubmittal()}
                    />
                  </>
                }
              />
            </div>
          </div>
        </>
      }
    />
  );
}

CreateNewHomesiteModal.propTypes = {
  isOpen: PropTypes.bool,

  onCollapse: PropTypes.func,

  onClick: PropTypes.func,

  onDelete: PropTypes.func,

  initialData: PropTypes.shape({
    name: PropTypes.string,
    code: PropTypes.string,
    location: PropTypes.string,
    line1: PropTypes.string,
    line2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    postalCode: PropTypes.string,
  }),
};
