import React, { useState, useEffect } from "react";
import { Dashboard } from "react-ikonate";
import { Person as PersonIcon } from "react-ikonate";
// import { Close as Cross, IkonateContext } from "react-ikonate";
// import close from "../../src/shared/assets/closeIcon.svg"
import {
  IconAdd,
  IconCalendar,
  IconAlert,
  IconDelete,
  IconDownload,
} from "@wwg/icons";
import { ReactComponent as IconReports } from "../Pages/ReportsPage/Reports_Icon.svg";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

import { Auth } from "shared/@auth/Auth";

import Page, { Content, Nav, NavGroup, NavItem } from "shared/Page";

import { useCurrentRoute } from "shared/@hooks/useCurrentRoute";

import Manage from "./Manage";
import ManageVenuesPage from "../Pages/ManageVenuesPage";
import ManageHomesitesPage from "../Pages/ManageHomesitesPage";
import OrdersByIBO from "./OrdersByIBO";
import ManageEventsPage from "../Pages/ManageEventsPage";
import ViewEventManagePage from "../Pages/ViewEventManagePage";
import ManageInviteesPage from "../Pages/ManageInviteesPage";
import ViewRulesetsPage from "../Pages/ViewRulesetsPage";
import NewEventModal from "./NewEventModal";
import ImportModal from "./ImportModal";
import EditEventPage from "../Pages/EditEventPage/index.js";
import EditMajorV1EventPage from "../Pages/EditMajorV1EventPage";
import EditLocalV1EventPage from "../Pages/EditLocalV1EventPage";
import EditLOSV1EventPage from "../Pages/EditLOSV1EventPage";
import ArchivedEventsPage from "../Pages/ArchivedEventsPage";
import "../../../src/App.module.css";
import ViewMajorV1EventManagePage from "../Pages/ViewMajorV1EventManagePage";
import ViewLocalV1EventManagePage from "../Pages/ViewLocalV1EventManagePage";
import ViewLOSV1EventManagePage from "../Pages/ViewLOSV1EventManagePage";
import EditMajorV2EventPage from "Events/Pages/EditMajorV2EventPage";
import EditLocalV2EventPage from "Events/Pages/EditLocalV2EventPage";
import EditLOSV2EventPage from "Events/Pages/EditLOSV2EventPage";
import ViewMajorV2EventManagePage from "../Pages/ViewMajorV2EventManagePage";
import ViewLocalV2EventManagePage from "../Pages/ViewLocalV2EventManagePage";
import ViewLOSV2EventManagePage from "../Pages/ViewLOSV2EventManagePage";
import ReportsPage from "../Pages/ReportsPage";

export function Events() {
  const { path } = useRouteMatch();

  const { setCurrentRoute } = useCurrentRoute();

  const { hasPermission } = Auth.useContainer();

  const [createNewOpened, setCreateNewOpened] = useState(false);
  const [importOpened, setImportOpened] = useState(false);

  useEffect(() => {
    setCurrentRoute("/events");
    return () => {
      setCurrentRoute("");
    };
  });

  const handleCreateClick = () => {
    setCreateNewOpened(true);
  };
  const handleImportClick = () => {
    setImportOpened(true);
  };

  return (
    <Page>
      <Nav>
        {hasPermission("events.onsite") && (
          <NavGroup title="Onsite">
            <NavItem to={`${path}/manage/`} icon={<Dashboard />}>
              Manage
            </NavItem>

            <NavItem to={`${path}/orders/ibo/`} icon={<PersonIcon />}>
              Order By IBO
            </NavItem>
          </NavGroup>
        )}

        {hasPermission("developer.*") && (
          //  {hasPermission("events.admin") && (
          <NavGroup title="Events">
            <NavItem to={`${path}/events-details`} icon={<Dashboard />}>
              Events
            </NavItem>

            {/* <NavItem to={`${path}/calendar`} icon={<IconCalendar />}>
                Calendar
              </NavItem> */}

            <NavItem to={`${path}/archived`} icon={<IconDelete />}>
              Archived
            </NavItem>

            <div style={{ marginBottom: "10px" }}>
              <NavItem to={`${path}/reports`} icon={<IconReports />}>
                Reports
              </NavItem>
            </div>

            <NavItem
              to={`${path}/create`}
              icon={<IconAdd />}
              onClick={handleCreateClick}
            >
              Create
            </NavItem>

            <NavItem
              to={`${path}/import`}
              icon={<IconDownload />}
              onClick={handleImportClick}
            >
              Import
            </NavItem>
          </NavGroup>
        )}

        {/* <NavGroup title="Analytics">
          {hasPermission("events.admin") && (
            <NavItem to={`${path}/event-explorer`} icon={<IconAlert />}>
              Event Explorer
            </NavItem>
          )}
          {hasPermission("events.admin") && (
            <NavItem to={`${path}/order-explorer`} icon={<IconAlert />}>
              Order Explorer
            </NavItem>
          )}
          {hasPermission("events.admin") && (
            <NavItem to={`${path}/speaker-explorer`} icon={<IconAlert />}>
              Speaker Explorer
            </NavItem>
          )}
        </NavGroup> */}
      </Nav>

      <Content>
        <Switch>
          {hasPermission("events.onsite") && (
            <Route path={`${path}/manage/:eventId?`}>
              <Manage />
            </Route>
          )}

          {hasPermission("events.onsite") && (
            <Route path={`${path}/orders/ibo/:iboNumber`}>
              <OrdersByIBO />
            </Route>
          )}

          {hasPermission("events.onsite") && (
            <Route path={`${path}/orders/ibo`}>
              <OrdersByIBO />
            </Route>
          )}

          {hasPermission("events.admin") && (
            <Route path={`${path}/events-details`}>
              <ManageEventsPage />
            </Route>
          )}

          {hasPermission("events.admin") && (
            <Route path={`${path}/archived`}>
              <ArchivedEventsPage />
            </Route>
          )}

          {hasPermission("events.admin") && (
            <Route exact path={`${path}/view/event/:eventId`}>
              <ViewEventManagePage />
            </Route>
          )}

          {/* VIEW VERSION 1 */}
          {hasPermission("events.admin") && (
            <Route exact path={`${path}/view/event/majorv1/:eventId`}>
              <ViewMajorV1EventManagePage />
            </Route>
          )}

          {hasPermission("events.admin") && (
            <Route exact path={`${path}/view/event/localv1/:eventId`}>
              <ViewLocalV1EventManagePage />
            </Route>
          )}

          {hasPermission("events.admin") && (
            <Route exact path={`${path}/view/event/losv1/:eventId`}>
              <ViewLOSV1EventManagePage />
            </Route>
          )}

          {/* VIEW VERSION 2 */}
          {hasPermission("events.admin") && (
            <Route exact path={`${path}/view/event/majorv2/:eventId`}>
              <ViewMajorV2EventManagePage />
            </Route>
          )}

          {hasPermission("events.admin") && (
            <Route exact path={`${path}/view/event/localv2/:eventId`}>
              <ViewLocalV2EventManagePage />
            </Route>
          )}

          {hasPermission("events.admin") && (
            <Route exact path={`${path}/view/event/losv2/:eventId`}>
              <ViewLOSV2EventManagePage />
            </Route>
          )}

          {hasPermission("events.admin") && (
            <Route exact path={`${path}/manage-invitees`}>
              <ManageInviteesPage />
            </Route>
          )}

          {hasPermission("events.admin") && (
            <Route path={`${path}/manage-venues`}>
              <ManageVenuesPage />
            </Route>
          )}

          {hasPermission("events.admin") && (
            <Route path={`${path}/view/rulesets`}>
              <ViewRulesetsPage />
            </Route>
          )}

          {hasPermission("events.admin") && (
            <Route path={`${path}/manage-homesites`}>
              <ManageHomesitesPage />
            </Route>
          )}

          {hasPermission("events.admin") && (
            <Route path={`${path}/calendar`}>COMING SOON - CALENDAR</Route>
          )}

          {hasPermission("events.admin") && (
            <Route exact path={`${path}/create`}>
              <NewEventModal
                isOpen={createNewOpened}
                onCollapse={() => setCreateNewOpened(false)}
                onEdit={false}
              />
            </Route>
          )}
          {hasPermission("events.admin") && (
            <Route exact path={`${path}/import`}>
              <ImportModal
                isOpen={importOpened}
                onCollapse={() => setImportOpened(false)}
                onEdit={false}
              />
            </Route>
          )}

          {hasPermission("events.admin") && (
            <Route exact path={`${path}/edit/:eventId`}>
              <EditEventPage />
            </Route>
          )}

          {/* EDIT VERSION 1 */}
          {hasPermission("events.admin") && (
            <Route exact path={`${path}/edit/event/majorv1/:eventId`}>
              <EditMajorV1EventPage />
            </Route>
          )}

          {hasPermission("events.admin") && (
            <Route exact path={`${path}/edit/event/localv1/:eventId`}>
              <EditLocalV1EventPage />
            </Route>
          )}

          {hasPermission("events.admin") && (
            <Route exact path={`${path}/edit/event/losv1/:eventId`}>
              <EditLOSV1EventPage />
            </Route>
          )}

          {/* EDIT VERSION 2 */}
          {hasPermission("events.admin") && (
            <Route exact path={`${path}/edit/event/majorv2/:eventId`}>
              {/* // <Route exact path={`${path}/edit/event/majorv2`}> */}
              <EditMajorV2EventPage />
            </Route>
          )}

          {hasPermission("events.admin") && (
            <Route exact path={`${path}/edit/event/localv2/:eventId`}>
              <EditLocalV2EventPage />
            </Route>
          )}

          {hasPermission("events.admin") && (
            <Route exact path={`${path}/edit/event/losv2/:eventId`}>
              <EditLOSV2EventPage />
            </Route>
          )}

          {hasPermission("events.admin") && (
            <Route path={`${path}/reports`}>
              <ReportsPage />
            </Route>
          )}

          {hasPermission("events.onsite") ? (
            <Route path="*">
              <Redirect to={`${path}/manage/`} />
            </Route>
          ) : (
            <Route path="*">
              <Redirect to={`${path}/events-details/`} />
            </Route>
          )}
        </Switch>
      </Content>
    </Page>
  );
}
