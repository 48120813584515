import PropTypes from "prop-types";
import styles from "./CircumstancesV2Form.module.css";
import Card from "shared/Card";
import TextArea from "shared/TextArea";

/**
 * Area to fill out notes for security team
 */

export function CircumstancesV2Form({
  circumstanceNotes,
  setCircumstanceNotes,
}) {
  return (
    <Card>
      <div className={styles.title}>SECURITY SPECIAL CIRCUMSTANCES</div>

      <hr className={styles.hr} />

      <div className={styles.security}>
        <TextArea
          label={"Notes for Security Team"}
          placeholder="Click to type"
          value={circumstanceNotes}
          onChange={(e) => setCircumstanceNotes(e.target.value)}
        />
      </div>
    </Card>
  );
}

CircumstancesV2Form.propTypes = {
  circumstanceNotes: PropTypes.string,
  setCircumstanceNotes: PropTypes.func,
};
