import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import PropTypes from "prop-types";
import classNames from "classnames";

import Card from "shared/Card";
import { useRef } from "react";
//import Button from "shared/Button";
import InputItem from "Events/Components/InputItem";
import AddButton from "Events/Components/AddButton";
import Select from "Events/Components/Select";
import DatePicker from "Events/Components/DatePicker";
import TimePicker from "Events/Components/TimePicker";
import TextArea from "shared/TextArea";
import Button from "shared/Button";
import styles from "./SessionsV2Form.module.css";
import DeleteModal from "Events/Components/DeleteModal";
import AddSpeakerModal from "Events/Components/AddSpeakerModal";
import moment from "moment-timezone";
import Link from "shared/Link";

/**Area to fill out session information */
export function SessionsV2Form({
  sessions,
  setSessions,
  onAddSpeaker,
  eventData,
  pageMode,
}) {
  const history = useHistory();

  const [splitRooms, setSplitRooms] = useState({});

  const [btnOpened, setBtnOpened] = useState(false);
  const [showSpeakerModal, setShowSpeakerModal] = useState(false);
  const [indexToRemove, setIndexToRemove] = useState(0);
  const [sessionSpeakerIndex, setSessionSpeakerIndex] = useState(0);

  const addBtnOnClick = () => {
    setBtnOpened(true);
  };

  const initialSession = {
    type: "",
    // ticketGroups: ["Spring Leadership", "Guest"],
    sessionName: "",
    description: "",
    speakers: [],
    // rooms: [],
    // room: "",
    capacity: "",
    startDate: "",
    endDate: "",
    startTime: "",
    endTime: "",
  };

  const [sessionsArray, setSessionsArray] = useState([]);

  const [showRemoveModal, changeShowRemoveModal] = useState(false);

  // const [localStartTimeZone, setLocalStartTimeZone] = useState("CST");
  // const [localEndTimeZone, setLocalEndTimeZone] = useState("CST");

  const timeZones = ["PST", "MST", "MDT", "CST", "CDT"];

  useEffect(() => {
    const sessionData = sessions.saved || sessions.value || [];
    if (sessionData && sessionData.length > 0) {
      setSessionsArray(sessionData);
    } else {
      // setSessions(initialSession);
      setSessionsArray(sessionData);
    }
  }, [sessions]);

  // useEffect(() => {
  //   const roomNames = newAddSessions.flatMap((session) =>
  //     (session.rooms || []).map((room) =>
  //       room.split("-").map((val) => val.trim())
  //     )
  //   );
  //   setSplitRooms(Object.fromEntries(roomNames));
  // }, [newAddSessions]);

  const handleSessionAdd = () => {
    const newSessions = [
      ...sessionsArray,
      {
        type: "",
        // ticketGroups: ["Spring Leadership", "Guest"],
        sessionName: "",
        description: "",
        speakers: [],
        // rooms: [],
        // room: "",
        capacity: "",
        startDate: "",
        endDate: "",
        startTime: "",
        endTime: "",
      },
    ];
    // setSessionsArray(newSessions);

    setSessions({});
  };

  // useUpdateSession logic embedded directly here
  const debounceTimer = useRef(null); // Ref to track the debounce timer
  const pendingUpdates = useRef({}); // Ref to track pending updates to avoid conflicts

  const updateSession = (index, key, value) => {
    // Update the frontend state immediately
    setSessionsArray((prevSessions) => {
      const updatedSessions = [...prevSessions];
      updatedSessions[index] = { ...updatedSessions[index], [key]: value };
      return updatedSessions;
    });

    // Store the latest pending update
    pendingUpdates.current = { index, key, value };

    // Clear the previous timer
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }

    // Schedule a new backend update
    debounceTimer.current = setTimeout(() => {
      const {
        index: pendingIndex,
        key: pendingKey,
        value: pendingValue,
      } = pendingUpdates.current;

      // Ensure we only make the update for the latest changes
      if (
        pendingIndex === index &&
        pendingKey === key &&
        pendingValue === value
      ) {
        const sessionToUpdate = sessionsArray[index];
        if (
          key == "startDate" ||
          key == "endDate" ||
          key == "startTime" ||
          key == "endTime"
        ) {
          let startDate = moment(sessionToUpdate.startDate || moment()); // Default to today's date
          let endDate = moment(sessionToUpdate.endDate || moment()); // Default to today's date
          let newDateString;

          if (key === "startDate") {
            const newDateMoment = moment(value, "MM/DD/YYYY");
            startDate.year(newDateMoment.year());
            startDate.month(newDateMoment.month());
            startDate.date(newDateMoment.date());

            // Default time to midnight (00:00:00.000)
            startDate.hour(0);
            startDate.minute(0);
            startDate.second(0);
            startDate.millisecond(0);

            newDateString = startDate.toISOString();
          } else if (key === "endDate") {
            const newDateMoment = moment(value, "MM/DD/YYYY");
            endDate.year(newDateMoment.year());
            endDate.month(newDateMoment.month());
            endDate.date(newDateMoment.date());

            // Default time to midnight (00:00:00.000)
            endDate.hour(0);
            endDate.minute(0);
            endDate.second(0);
            endDate.millisecond(0);

            newDateString = endDate.toISOString();
          } else if (key === "startTime") {
            const newTimeMoment = moment(value, "HH:mm");

            // Default date to today if no date exists
            if (!sessionToUpdate.startDate) {
              startDate = moment(); // Reset to today
            }

            startDate.hour(newTimeMoment.hour());
            startDate.minute(newTimeMoment.minute());
            startDate.second(0); // Reset seconds
            startDate.millisecond(0);

            newDateString = startDate.toISOString();
          } else if (key === "endTime") {
            const newTimeMoment = moment(value, "HH:mm");

            // Default date to today if no date exists
            if (!sessionToUpdate.endDate) {
              endDate = moment(); // Reset to today
            }

            endDate.hour(newTimeMoment.hour());
            endDate.minute(newTimeMoment.minute());
            endDate.second(0); // Reset seconds
            endDate.millisecond(0);

            newDateString = endDate.toISOString();
          }

          const formattedSession = {
            ...sessionToUpdate,
            [key.includes("start") ? "startDate" : "endDate"]: newDateString,
          };

          setSessions(formattedSession);
        } else {
          // const formattedSession = { _id: sessionToUpdate._id, [key]: value };
          const formattedSession = { ...sessionToUpdate, [key]: value };

          // console.log(
          //   "Backend update:",
          //   pendingIndex,
          //   pendingKey,
          //   pendingValue,
          //   formattedSession
          // );

          setSessions(formattedSession); // Backend update
        }
      }
    }, 800); // Adjust delay as needed
  };

  const onRemove = () => {
    const sessionToRemove = sessionsArray[indexToRemove];
    const formattedSession = {
      ...sessionToRemove,
      undo: true,
    };

    setSessions(formattedSession);
    changeShowRemoveModal(false);
  };

  const handleAddSpeaker = (speakers) => {
    const speakersToSend = speakers.filter(
      (speaker) =>
        !sessionsArray[sessionSpeakerIndex]?.speakers?.find(
          (speak) => speak.wwgId === speaker.wwgId
        )
    );
    updateSession(sessionSpeakerIndex, "speakers", speakersToSend);
  };
  const handleRemoveSpeaker = (speaker) => {
    const speakersToSend = [{ ...speaker, undo: true }];

    updateSession(sessionSpeakerIndex, "speakers", speakersToSend);
  };

  const renderAddSpeaker = (index) => {
    const speakerPermission = eventData?.speaker?.permission;

    const speakerSaved = sessionsArray[sessionSpeakerIndex]?.speakers;
    // const speakersToDisplay = speakerSaved?.length > 0 ? speakerSaved : speakerValue;

    if (speakerPermission === "write" || speakerPermission === undefined) {
      if (speakerSaved) {
        return (
          <>
            <div className={styles.speakerList}>
              {speakerSaved.map((speaker) => (
                <div className={styles.speakerItem}>
                  <Link to={"/events/events-details"}>
                    {speaker?.name ||
                      speaker?.iboNumber ||
                      "No Name For Speaker"}
                  </Link>
                  <div
                    className={styles.removeSpeaker}
                    onClick={() => handleRemoveSpeaker(speaker, index)}
                  >
                    &times;
                  </div>
                </div>
              ))}
            </div>
            <div
              className={classNames(styles["button-container"])}
              onClick={() => {
                setSessionSpeakerIndex(index);
                setShowSpeakerModal(true);
              }}
            >
              <AddButton description={"Add Speaker"} color="#2A3847" />
            </div>
          </>
        );
      }
      return (
        <div
          className={classNames(styles["button-container"])}
          onClick={() => {
            setSessionSpeakerIndex(index);
            setShowSpeakerModal(true);
          }}
        >
          <AddButton description={"Add Speaker"} color="#2A3847" />
        </div>
      );
    }
  };

  const redirectInvitees = () => {
    history.push("/events/manage-invitees");
  };

  return (
    <Card
      children={
        <section>
          <div className={styles.header}>
            <div className={styles.title}>SESSIONS</div>
            <div className={styles.button}>
              <Button children="Add" size="small" onClick={handleSessionAdd} />
            </div>
          </div>

          <div className={styles.container}>
            {sessionsArray.map((session, index) => (
              <div key={index} className={styles.section}>
                <hr className={styles.hr} />
                <div className={styles.row}>
                  <span className={styles.label}>Visibility</span>

                  <span className={styles.visibleTo}>
                    <Select
                      placeholder={session.type || "Select"}
                      value={session.visibility}
                      selectedOption={session.visibility || "Select"}
                      options={["Public", "Invite Only"]}
                      onChange={(type) =>
                        updateSession(index, "visibility", type)
                      }
                      removeSelectedOption={false}
                    />
                  </span>

                  <div className={styles.selectRemove}>
                    {session.type === "Invite Only" && (
                      <div className={styles.button}>
                        <Button
                          children="Manage Invitees"
                          size="small"
                          onClick={redirectInvitees}
                        />
                      </div>
                    )}

                    <div className={styles.remove}>
                      <Button
                        className={styles.removeButton}
                        children="Remove"
                        size="small"
                        onClick={
                          () => {
                            setIndexToRemove(index);
                            changeShowRemoveModal(true);
                          }
                          // sessionsArray.length >= 2
                          //   ? () => changeShowRemoveModal(true)
                          //   : null
                        }
                      />
                    </div>
                  </div>
                </div>

                {/* <div className={styles.row}>
                  <div className={styles.leftCol}>
                    <span className={styles.label}>Ticket Group</span>
                    <div className={styles.ticketGroupContainer}>
                      {session.ticketGroups.map((group, index) => (
                        <span
                          key={index}
                          className={classNames(
                            styles.ticketGroup,
                            styles.text
                          )}
                        >
                          {group}
                        </span>
                      ))}
                    </div>
                  </div>
                </div> */}
                <div className={styles.session}>
                  <InputItem
                    label="Session Name"
                    placeholder="Click to type"
                    value={session.name || ""}
                    onChange={(e) =>
                      updateSession(index, "name", e.target.value)
                    }
                  />
                </div>
                <div className={classNames(styles.description)}>
                  <TextArea
                    label={"Description"}
                    placeholder="Click to type"
                    value={session.description || ""}
                    onChange={(e) =>
                      updateSession(index, "description", e.target.value)
                    }
                  />
                </div>
                <div className={styles.row}>
                  <div className={styles.leftCol}>
                    <span className={styles.label}>Speaker Name</span>
                    {renderAddSpeaker(index)}
                  </div>
                </div>

                <div className={styles.row}>
                  {/* <div className={styles.finalCol}>
                    <span className={styles.label}>Room</span>
                    <span className={styles.rooms}>
                      <Select
                        options={Object.keys(splitRooms)}
                        placeholder={session.room || "Select"}
                        // onChange={setRoom}
                        onChange={(room) => updateSession(index, "room", room)}
                      />
                    </span>
                  </div>

                  <div className={styles.finalCol}>
                    <span className={styles.capacity}>
                      {splitRooms[session.room] || "Capacity"}
                    </span>
                  </div> */}

                  <div className={styles.session}>
                    <InputItem
                      label="Capacity"
                      placeholder="Click to type"
                      value={session.capacity || ""}
                      onChange={(e) =>
                        updateSession(index, "capacity", e.target.value)
                      }
                    />
                  </div>
                </div>

                <div className={styles.dateRow}>
                  <div className={styles.dateCol}>
                    <span className={styles.label}>Start Date</span>
                    <span className={styles.datePicker}>
                      <DatePicker
                        dateString={moment(session.startDate).format(
                          "MM/DD/YYYY"
                        )}
                        setDate={(sDate) =>
                          updateSession(index, "startDate", sDate)
                        }
                      />
                    </span>
                    <span className={styles.timePicker}>
                      <TimePicker
                        timeString={
                          session.startDate
                            ? moment(session.startDate).format("HH:mm")
                            : ""
                        }
                        onChange={(sTime) =>
                          updateSession(index, "startTime", sTime)
                        }
                      />
                    </span>
                    {/* <span className={styles.timezone}>
                      <Select
                        placeholder={session.localStartTimeZone || "CST"}
                        options={timeZones}
                        // onChange={setLocalStartTimeZone}
                        onChange={(zone) =>
                          updateSession(index, "localStartTimeZone", zone)
                        }
                      />
                    </span> */}
                  </div>

                  <div className={classNames(styles.dateCol)}>
                    <span className={styles.label}>End Date</span>
                    <span className={styles.datePicker}>
                      <DatePicker
                        dateString={moment(session.endDate).format(
                          "MM/DD/YYYY"
                        )}
                        setDate={(eDate) =>
                          updateSession(index, "endDate", eDate)
                        }
                      />
                    </span>
                    <span className={styles.timePicker}>
                      <TimePicker
                        timeString={
                          session.endDate
                            ? moment(session.endDate).format("HH:mm")
                            : ""
                        }
                        // onChange={setEndTime}
                        onChange={(eTime) =>
                          updateSession(index, "endTime", eTime)
                        }
                      />
                    </span>
                    {/* <span className={styles.timezone}>
                      <Select
                        placeholder={session.localEndTimeZone || "CST"}
                        options={timeZones}
                        // onChange={setLocalEndTimeZone}
                        onChange={(zone) =>
                          updateSession(index, "localEndTimeZone", zone)
                        }
                      />
                    </span> */}
                  </div>
                </div>
              </div>
            ))}
          </div>

          <DeleteModal
            header={"REMOVE SESSION"}
            description={"Confirm that you would like to remove session?"}
            isOpen={showRemoveModal}
            onCollapse={() => changeShowRemoveModal(false)}
            onClick={() => onRemove()}
            btnName={"REMOVE"}
          />

          <AddSpeakerModal
            isOpen={showSpeakerModal}
            onCollapse={() => {
              setSessionSpeakerIndex(0);
              setShowSpeakerModal(false);
            }}
            onClick={(speakers) => handleAddSpeaker(speakers)}
            speakers={eventData?.speakers?.value}
          />
        </section>
      }
    />
  );
}

SessionsV2Form.propTypes = {
  /**Session type displays the name of the session that is assigned by IT during the population of the template. This field is not editable.  */
  type: PropTypes.string,
  /**Ticket group displays the ticket groups that are allowed to attend the session and are assign by IT during the population of the template. This field is not editable.  */
  ticketGroups: PropTypes.arrayOf(PropTypes.string),
  /**Value of session name  */
  sessionName: PropTypes.string,
  /**Sets the value of the session name when changed  */
  setSessionName: PropTypes.func,
  /**Description of the session  */
  description: PropTypes.string,
  /**Sets the value of the description when changed  */
  setDescription: PropTypes.func,
  /**Array of speaker objects  */
  speakers: PropTypes.arrayOf(PropTypes.shape({})),
  /**Function used when adding more speakers  */
  setSpeakers: PropTypes.func,
  /**Array containing the rooms associated with the venue selected in the venue form earlier in the page */
  rooms: PropTypes.arrayOf(PropTypes.string),
  /**Value of the room selected  */
  room: PropTypes.string,
  /**Sets the value of the room when changed  */
  setRoom: PropTypes.func,
  /**Value of the start date  */
  startDate: PropTypes.string,
  /**Sets the value of the start date when changed  */
  setStartDate: PropTypes.func,
  /**Value of the end date  */
  endDate: PropTypes.string,
  /**Sets the value of the end date when changed  */
  setEndDate: PropTypes.func,
  /**Value of the start time  */
  startTime: PropTypes.string,
  /**Sets the value of the start time when changed  */
  setStartTime: PropTypes.func,
  /**Value of the end time  */
  endTime: PropTypes.string,
  /**Sets the value of the end time when changed  */
  setEndTime: PropTypes.func,
};
