import { Auth } from "shared/@auth/Auth";
import { useQuery } from "react-query";

export const useEventIBOSearch = ({
  term,
  pin,
  limit = 25,
  page = 1,
  sort,
}) => {
  const { makeAuthedRequest } = Auth.useContainer();

  const { data, isError, isLoading, refetch } = useQuery(
    ["iboSearch", { term, pin, limit, page, sort }],
    () =>
      makeAuthedRequest(
        `v1/events/event/ibo/search?term=${term}&pin=${pin}&limit=${limit}&page=${page}&sort=${sort}`,
        {
          method: "GET",
        }
      ).then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const body = await res.json();
          throw new Error(body.message);
        }
      }),
    {
      staleTime: 0,
      enabled: !!sort,
    }
  );

  return {
    data,
    refetch,
    isError,
    isLoading,
  };
};
