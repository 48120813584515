import { useState } from "react";
import { useQuery, useMutation } from "react-query";
import { Auth } from "shared/@auth/Auth";

// Mock Homesites Data
const mockHomesites = [
  {
    _id: "674e18299a5e8935981ace94",
    regionId: 2,
    name: "ANCHORAGE",
    code: "ANC",
    isActive: true,
    location: "ARCTIC REC CENTER",
    city: null,
    street1: null,
    street2: null,
    zip: null,
    state: null,
    country: null,
    latitude: null,
    longitude: null,
    isVerified: null,
    id: "674e18299a5e8935981ace94",
  },
  {
    _id: "674e18299a5e8935981ace95",
    regionId: 3,
    name: "ANN ARBOR",
    code: "ANN",
    isActive: true,
    location: "DETROIT METRO AIRPORT MARRIOTT",
    city: "ROMULUS",
    street1: "30559 FLYNN DR.",
    street2: "",
    zip: "",
    state: "MI",
    country: "US",
    latitude: 42.24161,
    longitude: -83.33823,
    isVerified: false,
    id: "674e18299a5e8935981ace95",
  },
];

export const useManageHomesitesPage = () => {
  const { makeAuthedRequest } = Auth.useContainer();
  const [homesites, setHomesites] = useState(mockHomesites);

  // resolveAddress
  // const resolveAddress = {
  //   mutateAsync: async (addressData) => {
  //     console.log("Resolving Address:", addressData);
  //     // Simulate returning suggestions
  //     return addressData;
  //     // {
  //       // suggestions: [
  //       //   {
  //       //     line1: "Resolved Line 1",
  //       //     city: "Resolved City",
  //       //     state: "Resolved State",
  //       //     postalCode: "00000",
  //       //     country: "Resolved Country",
  //       //   },
  //       // ],
  //     // };
  //   },
  // };
  const resolveAddress = useMutation((resolveAddressData) =>
    makeAuthedRequest(`v1/events/venues/resolve-address`, {
      method: "POST",
      body: JSON.stringify(resolveAddressData),
    }).then(async (res) => {
      if (res.ok) {
        return res.json();
      } else {
        const body = await res.json();
        throw new Error(body.message);
      }
    })
  );

  // updateHomesite
  const updateHomesite = {
    mutateAsync: async (updatedHomesite) => {
      //console.log("Updating Homesite:", updatedHomesite);
      const updatedList = homesites.map((site) =>
        site._id === updatedHomesite._id ? updatedHomesite : site
      );
      setHomesites(updatedList);
      return updatedHomesite;
    },
  };

  return {
    homesites,
    resolveAddress,
    updateHomesite,
  };
};
