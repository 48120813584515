import React, { useState, useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import Card from "shared/Card";
import styles from "./PublishingV2InfoForm.module.css";
import Checkbox from "shared/@forms/Checkbox";
import InputItem from "Events/Components/InputItem";
import AddButton from "Events/Components/AddButton";
import { Select } from "shared/Select/Select";

import Loader from "shared/Loader";
import VisibleToModal from "Events/Components/VisibleToModal";
import DateV2Section from "../DateV2Section";

//Area to fill out the publishing information
export function PublishingV2InfoForm({
  publishingInfo,
  onPublishChange,
  pageMode,
  onDateTimeChange,
}) {
  const [checkboxStates, setCheckboxStates] = useState({});
  const [inputValues, setInputValues] = useState({});
  const initialInputValuesRef = useRef({});
  const [loadingValue, setLoadingValue] = useState({});
  const [openVisibleToModal, setOpenVisibleToModal] = useState(false);
  const [visibleTo, setVisibleTo] = useState(false);
  const [bookingStatusSelected, setBookingStatusSelected] = useState("");

  const isRulesetLocal = publishingInfo.event.ruleset.value;

  useEffect(() => {
    const bookingStatus = publishingInfo?.marketing?.bookingStatus;
    setBookingStatusSelected(
      bookingStatus?.saved ??
        bookingStatus?.value ??
        (typeof bookingStatus === "string" ? bookingStatus : "")
    );
    setVisibleTo(
      visibleTo?.saved ??
        visibleTo?.value ??
        (typeof visibleTo === "string" ? visibleTo : "")
    );
  }, [publishingInfo]);

  useEffect(() => {
    setCheckboxStates({
      marketing: {
        visibleCalendar:
          publishingInfo?.marketing?.visibleCalendar?.saved ??
          publishingInfo?.marketing?.visibleCalendar?.value ??
          "",
        tentative:
          publishingInfo?.marketing?.tentative?.saved ??
          publishingInfo?.marketing?.tentative?.value ??
          "",
      },
      analytics: {
        countingTickets:
          publishingInfo?.analytics?.countingTickets?.saved ??
          publishingInfo?.analytics?.countingTickets?.value ??
          "",
        sales: {
          soldOut:
            publishingInfo?.analytics?.sales?.soldOut?.saved ??
            publishingInfo?.analytics?.sales?.soldOut?.value ??
            "",
        },
      },
    });
  }, [publishingInfo]);

  useEffect(() => {
    setInputValues({
      purchase:
        publishingInfo?.analytics?.sales.capacity?.purchase?.saved ??
        publishingInfo?.analytics?.sales.capacity?.purchase?.value ??
        "",

      platinum:
        publishingInfo?.analytics?.sales.capacity?.platinum?.saved ??
        publishingInfo?.analytics?.sales.capacity?.platinum?.value ??
        "",

      estimatedAttendance:
        publishingInfo?.analytics?.sales.capacity?.estimatedAttendance?.saved ??
        publishingInfo?.analytics?.sales.capacity?.estimatedAttendance?.value ??
        "",
    });
  }, [publishingInfo]);

  const handleCheckboxChange = (section, field, isChecked) => {
    const newStates = { ...checkboxStates };

    const fullPath =
      section === "analytics" && field === "soldOut"
        ? "analytics.sales.soldOut"
        : `${section}.${field}`;

    const newSavedValue = isChecked ? "true" : "false";

    // Immediately update UI
    newStates[section][field] = {
      ...newStates[section][field],
      value: isChecked,
      saved: newSavedValue,
    };
    setCheckboxStates(newStates);

    setLoadingValue((prev) => ({ ...prev, [fullPath]: true }));
    onPublishChange({ path: fullPath, value: newSavedValue })
      .then(() => {
        // Confirm the change was successful and update the saved state
        setCheckboxStates((prev) => ({
          ...prev,
          [section]: {
            ...prev[section],
            [field]: {
              ...prev[section][field],
              saved: newSavedValue,
            },
          },
        }));
      })
      .catch((error) => {
        console.error("API call failed for", fullPath, error);
        // Revert the checkbox state in case of an error
        setCheckboxStates((prev) => ({
          ...prev,
          [section]: {
            ...prev[section],
            [field]: {
              ...prev[section][field],
              value: !isChecked,
              saved: !isChecked ? "true" : "false",
            },
          },
        }));
      })
      .finally(() => {
        setLoadingValue((prev) => ({ ...prev, [fullPath]: false }));
      });
  };

  const handleInputChange = (key, newValue) => {
    setInputValues((prevValues) => ({
      ...prevValues,
      [key]: newValue,
    }));
  };

  const handleInputFocus = (key) => {
    initialInputValuesRef.current[key] = inputValues[key] ?? "";
  };

  const handleInputBlur = (key) => {
    const currentValue = inputValues[key];
    const initialValue = initialInputValuesRef.current[key];
    if (currentValue !== initialValue) {
      setLoadingValue((prev) => ({ ...prev, [key]: true }));

      const updatePath = `analytics.sales.capacity.${key}`;
      // Ensure that onPublishChange returns a promise
      onPublishChange({ path: updatePath, value: currentValue })
        .catch((error) => {
          console.error("API call failed for", key, error);
        })
        .finally(() => {
          setLoadingValue((prev) => ({
            ...prev,
            [key]: false,
          }));
        });
    }
  };

  const handleBookingStatusChange = (newValue) => {
    if (newValue !== bookingStatusSelected) {
      onPublishChange({
        path: "marketing.bookingStatus",
        value: newValue,
      })
        .catch((error) => {
          console.error("API call failed for Booking Status", error);
        })
        .finally(() => {
          setBookingStatusSelected(newValue);
        });
    }
  };

  const handleVisibleToChange = (newValue) => {
    if (newValue !== visibleTo) {
      onPublishChange({
        path: "marketing.visibleTo",
        value: newValue,
      })
        .catch((error) => {
          console.error("API call failed for Visible To", error);
        })
        .finally(() => {
          setVisibleTo(newValue);
        });
    }
  };

  const renderCheckbox = (section, fieldIdentifier, onChange, key) => {
    const field =
      section === "marketing"
        ? publishingInfo?.marketing[fieldIdentifier]
        : section === "analytics" && fieldIdentifier === "soldOut"
        ? publishingInfo?.analytics?.sales[fieldIdentifier]
        : publishingInfo?.analytics[fieldIdentifier];

    const checkboxState = checkboxStates[`${section}.${fieldIdentifier}`];
    const isLoading = loadingValue[`${section}.${fieldIdentifier}`];

    const fallbackChecked =
      checkboxState?.value ?? field?.saved ?? field?.value ?? false;

    const checkedStatus =
      checkboxState !== undefined ? checkboxState : fallbackChecked;

    const permission = pageMode === "view" ? "read" : field?.permission;

    if (permission === "write" || permission === undefined) {
      return (
        <>
          <span className={styles.checkbox}>
            <Checkbox
              checked={checkedStatus}
              disabled={isLoading}
              onChange={(e) => {
                handleCheckboxChange(
                  section,
                  fieldIdentifier,
                  e.target.checked
                );
              }}
            />
          </span>
        </>
      );
    } else if (permission === "read") {
      return (
        <>
          <span className={styles.textValue}>
            {field === true || field?.saved === true || field?.value === true
              ? "Yes"
              : "No"}
          </span>
        </>
      );
    }
    if (permission === "hidden") {
      return null;
    }
  };

  const renderInput = (field, label, key) => {
    const permission = pageMode === "view" ? "read" : field?.permission;

    const isPlatinumDisabled =
      (key === "platinum" || key === "estimatedAttendance") &&
      loadingValue["purchase"];
    const inputValue = inputValues[key] ?? field?.saved ?? field?.value ?? 0;
    const isLoading = loadingValue[key];

    //Adjust alignment of capacity or attendance
    const labelStyle = (() => {
      if (label) {
        return {
          minWidth: "184px",
          display: "inline-block",
        };
      }
    })();

    // If permission is hidden, don't render anything
    if (
      permission === "hidden" ||
      (pageMode === "view" && field?.permission === "hidden")
    ) {
      return null;
    }

    if (permission === "read" || pageMode === "view") {
      return (
        <div className={styles.colValue}>
          <span className={styles.label} style={labelStyle}>
            {label}
          </span>
          <span className={styles.textInput}>{inputValue || 0}</span>
        </div>
      );
    }

    return (
      <>
        {/* {isLoading && !inputValue ? (
          <div className={styles.loader}>
            <div>
              <Loader />
            </div>
          </div>
        ) : ( */}
        <InputItem
          label={`${label}${field?.required == true ? " *" : ""}`}
          value={inputValue}
          // placeholder="0"
          onChange={(e) => handleInputChange(key, e.target.value)}
          onBlur={() => handleInputBlur(key)}
          onFocus={() => handleInputFocus(key)}
          className={isPlatinumDisabled ? styles.disabled : styles.inputItem}
          // disabled={isPlatinumDisabled}
          tabIndex={isPlatinumDisabled ? -1 : 0}
          style={{ cursor: isPlatinumDisabled ? "not-allowed" : "auto" }}
        />
        {/* )} */}
      </>
    );
  };

  const renderVisibleTo = () => {
    const visiblePermission =
      pageMode === "view"
        ? "read"
        : publishingInfo?.marketing?.visibleCalendar?.permission;

    const visibleToValue = publishingInfo?.marketing?.visibleCalendar?.value;

    if (visiblePermission === "write" || visiblePermission === undefined) {
      return (
        <>
          <span className={styles.visibleToSelect}>
            <Select
              placeholder={visibleTo || "Select"}
              value={visibleTo}
              options={["IBO", "Comp IBO", "Platinum and above"]}
              onChange={(value) => handleVisibleToChange(value)}
            />
          </span>
          {visibleTo === "Invite Only" && (
            <span
              className={styles.visibleAddButton}
              onClick={() => setOpenVisibleToModal(true)}
            >
              <AddButton description={"Add"} color={"#2A3847"} />
            </span>
          )}
        </>
      );
    } else if (visiblePermission === "read") {
      return (
        <span className={styles.textValue}>{visibleToValue || "N/A"}</span>
      );
    } else if (visiblePermission === "hidden") {
      return null;
    }
  };

  const countingTicketsValue =
    publishingInfo?.analytics?.countingTickets?.saved ||
    publishingInfo?.analytics?.countingTickets?.value;
  const isCountingTicketsChecked =
    countingTicketsValue === true || countingTicketsValue === "Yes";

  const handleAddVisibleTo = () => {
    setVisibleTo("");
    setOpenVisibleToModal(false);
  };

  const handleCloseVisibleModal = () => {
    setVisibleTo("");
    setOpenVisibleToModal(false);
  };

  // const handleTentative = () => {
  //   setTentative(!tentative);
  // };

  return (
    <Card
      children={
        <section className={styles.container}>
          <div className={styles.title}>PUBLISHING INFORMATION</div>

          <hr className={styles.hr} />

          <div className={styles.row}>
            <div className={styles.visibleToCol}>
              {pageMode == "edit" ? (
                <>
                  <span className={styles.label}>
                    Booking Status
                    {publishingInfo.marketing.bookingStatus?.required && " *"}
                  </span>
                  <span className={styles.visibleToSelect}>
                    <Select
                      placeholder={bookingStatusSelected || "Select"}
                      value={bookingStatusSelected}
                      options={["Tentative", "Contracted", "Booked"]}
                      onChange={(value) => {
                        handleBookingStatusChange(value);
                      }}
                      className={styles.bookingStatusDropdown}
                    />
                  </span>
                </>
              ) : (
                <>
                  <span className={styles.label}>Booking Status</span>
                  <div className={styles.bookingText}>
                    {bookingStatusSelected || "N/A"}
                  </div>
                </>
              )}
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.tentative}>
              <span className={styles.label}>Tentative</span>
              {renderCheckbox("marketing", "tentative", onPublishChange)}
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.col}>
              <span className={styles.label}>Sold Out</span>
              {renderCheckbox("analytics", "soldOut", onPublishChange)}
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.col}>
              <span className={styles.label}>Counting Tickets</span>

              {renderCheckbox("analytics", "countingTickets", onPublishChange)}
            </div>
          </div>

          {isRulesetLocal === "Local v2.0" ? (
            <>
              {isCountingTicketsChecked ? (
                <>
                  <div className={styles.colInput}>
                    {renderInput(
                      publishingInfo?.analytics?.sales?.capacity?.purchase,
                      "Purchase Capacity",
                      "purchase"
                    )}
                  </div>

                  {/* {loadingValue["purchase"] !== true && ( */}
                  <div className={styles.colInput}>
                    {renderInput(
                      publishingInfo?.analytics?.sales?.capacity
                        ?.estimatedAttendance,
                      "Estimated Attendance",
                      "estimatedAttendance"
                    )}
                  </div>
                  {/* )} */}
                </>
              ) : null}
            </>
          ) : (
            <>
              {isCountingTicketsChecked ? (
                <>
                  <div className={styles.colInput}>
                    {renderInput(
                      publishingInfo?.analytics?.sales?.capacity?.purchase,
                      "Purchase Capacity",
                      "purchase"
                    )}
                  </div>

                  {/* {loadingValue["purchase"] !== true && ( */}
                  <div className={styles.colInput}>
                    {renderInput(
                      publishingInfo?.analytics?.sales?.capacity?.platinum,
                      "Platinum Capacity",
                      "platinum"
                    )}
                  </div>
                  {/* )} */}
                </>
              ) : null}
            </>
          )}

          <div className={styles.row}>
            <div className={styles.visibleToCol}>
              <span className={styles.label}>Visible to *</span>

              {renderVisibleTo()}
            </div>
          </div>

          <div className={styles.datesSection}>
            {publishingInfo && (
              <DateV2Section
                location={publishingInfo?.event?.location?.value}
                ruleset={publishingInfo?.event?.ruleset?.value}
                eventName={publishingInfo?.event?.name?.value}
                dateInfo={publishingInfo?.event}
                onDateTimeChange={onDateTimeChange}
                pageMode={pageMode}
                section="Publishing Information"
              />
            )}
          </div>

          <VisibleToModal
            isOpen={openVisibleToModal}
            onCollapse={handleCloseVisibleModal}
            onClick={handleAddVisibleTo}
          />
        </section>
      }
    />
  );
}

PublishingV2InfoForm.propTypes = {
  //Value of visible on date
  visibleOnDate: PropTypes.string,
  //Used to change the visible on date
  setVisibleOnDate: PropTypes.func,
  //Value of visible on time
  visibleOnTime: PropTypes.string,
  //used to set the visible on time
  setVisibleOnTime: PropTypes.func,
  //value of purchase begin date
  purchaseBeginDate: PropTypes.string,
  //used to set the purchase begin date
  setPurchaseBeginDate: PropTypes.func,
  //value of the purchase begin time
  purchaseBeginTime: PropTypes.string,
  //used to set the purchase begin time
  setPurchaseBeginTime: PropTypes.func,
  //value of purchase end time
  purchaseEndTime: PropTypes.string,
  //used to set the purchase end time
  setPurchaseEndTime: PropTypes.func,
  //value of the purchase end date
  purchaseEndDate: PropTypes.string,
  //used to set the purchase end date
  setPurchaseEndDate: PropTypes.func,
  //value of visible to select
  visibleTo: PropTypes.string,
  //used to set the value of the visible to select
  setVisibleTo: PropTypes.func,
};
