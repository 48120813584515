import React, { useState } from "react";
import PropTypes from "prop-types";
import styles from "./UndoChangesModal.module.css";
import { Modal } from "shared/Modal";
import DeleteModal from "Events/Components/DeleteModal";

/**
 * Modal that opens when clicking undo changes button in edit events page
 */
export function UndoChangesModal({
  editedEvent,
  isOpen,
  onCollapse,
  onClick,
  className,
}) {
  function onClose() {
    onCollapse();
  }

  return (
    <>
      <Modal
        title="CHANGES"
        isOpen={isOpen}
        onClose={onClose}
        closeBtn="none"
        className={`${styles.modal} ${className}`}
        children={
          <>
            <div className={styles.container}>
              <div className={styles.header}>
                <div>Field</div>
                <div>Original Value</div>
                <div>New Saved Value</div>
              </div>
              <div className={styles.hr} />

              <div className={styles.itemContainer}>
                {editedEvent &&
                  editedEvent.map((change, index) =>
                    change.original_value != change.saved_value ? (
                      <>
                        {change.field_name == "sessions" ? (
                          <>
                            {change.original_value.map((session, index) => {
                              // Get the corresponding session from saved_value (if it exists)
                              const savedSession =
                                change.saved_value.find(
                                  (ses) => ses._id == session._id
                                ) || {};

                              return (
                                <>
                                  {session.id &&
                                    savedSession.id !== session.id && (
                                      <div
                                        key={`${session.id || index}`}
                                        className={styles.item}
                                      >
                                        <div className={styles.field}>
                                          {`Session #${index + 1}`}
                                        </div>
                                        <div>
                                          <div
                                            className={styles.savedValueList}
                                          >
                                            <div
                                              className={
                                                styles.savedValueListItem
                                              }
                                            >
                                              {session.id
                                                ? `ID: ${session.id}
                                             Name: ${session.name || "No Name"}
                                             Description: ${
                                               session.description ||
                                               "No Description"
                                             }
                                             Capacity: ${
                                               session.capacity ||
                                               "No Capacity Set"
                                             }
                                             Start Date: ${
                                               session.startDate ||
                                               "No Start Date Set"
                                             }
                                             End Date: ${
                                               session.endDate ||
                                               "No End Date Set"
                                             }
                                             
                                             `
                                                : ""}
                                            </div>
                                          </div>
                                        </div>
                                        <div>
                                          <div
                                            className={styles.savedValueList}
                                          >
                                            <div
                                              className={
                                                styles.savedValueListItem
                                              }
                                            >
                                              {savedSession.id
                                                ? `ID: ${savedSession.id}
                                             Name: ${
                                               savedSession.name || "No Name"
                                             }
                                             Description: ${
                                               savedSession.description ||
                                               "No Description"
                                             }
                                             Capacity: ${
                                               savedSession.capacity ||
                                               "No Capacity Set"
                                             }
                                             Start Date: ${
                                               savedSession.startDate ||
                                               "No Start Date Set"
                                             }
                                             End Date: ${
                                               savedSession.endDate ||
                                               "No End Date Set"
                                             }
                                             
                                             `
                                                : ""}
                                            </div>
                                          </div>
                                        </div>
                                        <button
                                          className={styles.undoBtn}
                                          onClick={() => {
                                            const returnPath = `${change.path}.${session.id}.session`;
                                            onClick(
                                              index,
                                              returnPath,
                                              change.field_name, // Used to update the data
                                              change.original_value,
                                              change.field // Displays in Undo Confirmation modal
                                            );
                                          }}
                                        >
                                          UNDO
                                        </button>
                                      </div>
                                    )}
                                </>
                              );
                            })}
                            {change.saved_value.map((session, index) => {
                              // Get the corresponding session from original_value (if it exists)
                              const originalSession =
                                change.original_value.find(
                                  (ses) => ses._id == session._id
                                ) || {};

                              return (
                                <>
                                  {session.id &&
                                    originalSession.id !== session.id && (
                                      <div
                                        key={`${session.id || index}`}
                                        className={styles.item}
                                      >
                                        <div className={styles.field}>
                                          {`Session #${index + 1}`}
                                        </div>
                                        <div>
                                          <div
                                            className={styles.savedValueList}
                                          >
                                            <div
                                              className={
                                                styles.savedValueListItem
                                              }
                                            >
                                              {originalSession.id
                                                ? `ID: ${originalSession.id}
                                             Name: ${
                                               originalSession.name || "No Name"
                                             }
                                             Description: ${
                                               originalSession.description ||
                                               "No Description"
                                             }
                                             Capacity: ${
                                               originalSession.capacity ||
                                               "No Capacity Set"
                                             }
                                             Start Date: ${
                                               originalSession.startDate ||
                                               "No Start Date Set"
                                             }
                                             End Date: ${
                                               originalSession.endDate ||
                                               "No End Date Set"
                                             }
                                             
                                             `
                                                : ""}
                                            </div>
                                          </div>
                                        </div>
                                        <div>
                                          <div
                                            className={styles.savedValueList}
                                          >
                                            <div
                                              className={
                                                styles.savedValueListItem
                                              }
                                            >
                                              {session.id
                                                ? `ID: ${session.id}
                                             Name: ${session.name || "No Name"}
                                             Description: ${
                                               session.description ||
                                               "No Description"
                                             }
                                             Capacity: ${
                                               session.capacity ||
                                               "No Capacity Set"
                                             }
                                             Start Date: ${
                                               session.startDate ||
                                               "No Start Date Set"
                                             }
                                             End Date: ${
                                               session.endDate ||
                                               "No End Date Set"
                                             }
                                             
                                             `
                                                : ""}
                                            </div>
                                          </div>
                                        </div>
                                        <button
                                          className={styles.undoBtn}
                                          onClick={() => {
                                            const returnPath = `${change.path}.${session.id}.session`;
                                            onClick(
                                              index,
                                              returnPath,
                                              change.field_name, // Used to update the data
                                              change.original_value,
                                              change.field // Displays in Undo Confirmation modal
                                            );
                                          }}
                                        >
                                          UNDO
                                        </button>
                                      </div>
                                    )}
                                  {Object.keys(session)
                                    .filter((prop) => {
                                      if (prop === "speakers") {
                                        // Extract and compare IDs from original_value and session[prop]
                                        const originalIds =
                                          change.original_value
                                            .map((item) => item.id)
                                            .sort();
                                        const savedIds = session.speakers
                                          .map((item) => item.id)
                                          .sort();
                                        const areSame =
                                          JSON.stringify(originalIds) ===
                                          JSON.stringify(savedIds);

                                        // Only display if IDs are different
                                        return !areSame;
                                      }
                                      if (prop === "id" || prop === "_id")
                                        return false;
                                      // Include fields that differ or have no original value but are non-empty
                                      if (prop !== "speakers") {
                                        const hasOriginal = originalSession[
                                          prop
                                        ]
                                          ? true
                                          : false;
                                        const hasSaved = session[prop]
                                          ? true
                                          : false;
                                        if (!hasSaved) {
                                          return false;
                                        } else {
                                          if (hasOriginal) {
                                            return (
                                              originalSession[prop] !==
                                              session[prop]
                                            );
                                          } else {
                                            return true;
                                          }
                                        }
                                      }
                                    })
                                    .map((prop) => (
                                      <div
                                        key={`${session.id || index}-${prop}`}
                                        className={styles.item}
                                      >
                                        <div className={styles.field}>
                                          {`Session #${index + 1} - ${
                                            prop === "startDate" ||
                                            prop === "endDate"
                                              ? prop
                                                  .replace(
                                                    /([a-z])([A-Z])/g,
                                                    "$1 $2"
                                                  )
                                                  .charAt(0)
                                                  .toUpperCase() +
                                                prop
                                                  .replace(
                                                    /([a-z])([A-Z])/g,
                                                    "$1 $2"
                                                  )
                                                  .slice(1)
                                              : prop.charAt(0).toUpperCase() +
                                                prop.slice(1)
                                          }
                                          `}
                                        </div>
                                        <div>
                                          {!Array.isArray(
                                            originalSession[prop]
                                          ) ? (
                                            originalSession[prop] || ""
                                          ) : (
                                            <div
                                              className={styles.savedValueList}
                                            >
                                              {originalSession[prop].map(
                                                (item) => (
                                                  <div
                                                    className={
                                                      styles.savedValueListItem
                                                    }
                                                  >
                                                    {`${item.iboNumber}: ${item.name}`}
                                                  </div>
                                                )
                                              )}
                                            </div>
                                          )}
                                        </div>
                                        <div>
                                          {!Array.isArray(session[prop]) ? (
                                            session[prop] || ""
                                          ) : (
                                            <div
                                              className={styles.savedValueList}
                                            >
                                              {session[prop].map((item) => (
                                                <div
                                                  className={
                                                    styles.savedValueListItem
                                                  }
                                                >
                                                  {`${item.iboNumber}: ${item.name}`}
                                                </div>
                                              ))}
                                            </div>
                                          )}
                                        </div>
                                        <button
                                          className={styles.undoBtn}
                                          onClick={() => {
                                            const returnPath = `${change.path}.${session.id}.${prop}`;
                                            onClick(
                                              index,
                                              returnPath,
                                              change.field_name, // Used to update the data
                                              change.original_value,
                                              change.field // Displays in Undo Confirmation modal
                                            );
                                          }}
                                        >
                                          UNDO
                                        </button>
                                      </div>
                                    ))}
                                </>
                              );
                            })}
                          </>
                        ) : (
                          <div key={index} className={styles.item}>
                            <div className={styles.field}> {change.field}</div>
                            <div>
                              {!Array.isArray(change.original_value) ? (
                                change.original_value
                              ) : (
                                <div className={styles.savedValueList}>
                                  {change.original_value.map((item) => (
                                    <div className={styles.savedValueListItem}>
                                      {`${item.iboNumber}: ${item.name}`}
                                    </div>
                                  ))}
                                </div>
                              )}
                            </div>
                            <div>
                              {!Array.isArray(change.saved_value) ? (
                                change.saved_value
                              ) : (
                                <div className={styles.savedValueList}>
                                  {change.saved_value.map((item) => (
                                    <div className={styles.savedValueListItem}>
                                      {`${item.iboNumber}: ${item.name}`}
                                    </div>
                                  ))}
                                </div>
                              )}
                            </div>

                            <button
                              className={styles.undoBtn}
                              onClick={() => {
                                onClick(
                                  index,
                                  change.path,
                                  change.field_name, // Used to update the data
                                  change.original_value,
                                  change.field // Displays in Undo Confirmation modal
                                );
                              }}
                            >
                              UNDO
                            </button>
                          </div>
                        )}
                      </>
                    ) : (
                      ""
                    )
                  )}
              </div>

              <div className={styles.hr} />
              <div className={styles.button}>
                <button className={styles.closeBtn} onClick={onClose}>
                  CLOSE
                </button>
              </div>
            </div>
          </>
        }
      />
    </>
  );
}

UndoChangesModal.propTypes = {
  isOpen: PropTypes.bool,
  onCollapse: PropTypes.func,
  eventChanges: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
      original_value: PropTypes.string,
      saved_value: PropTypes.string,
    })
  ),
};

UndoChangesModal.defaultProps = {
  eventChanges: [],
};
