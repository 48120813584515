import React, { useState, useEffect } from "react";
import { Modal } from "../../../shared/Modal";
import { Select } from "../../../shared/Select/Select";
import SearchBar from "shared/SearchBar";
import GroupItem from "../GroupItem";
import PaginationControls from "shared/PaginationControls";
import Button from "shared/Button";
import TableSortHeader, { TableSortValue } from "shared/TableSortHeader";
import PropTypes from "prop-types";
import classNames from "classnames";
import moment from "moment-timezone";
import Loader from "shared/Loader";

import styles from "./AddGroupModal.module.css";
import ConfirmSpeakerModal from "Events/Components/ConfirmSpeakerModal";

import { useEventIBOSearch } from "./useEventIBOSearch";

/**
 * Modal that displays the groups and allows you to filter and sort them
 */
export function AddGroupModal({ isOpen, onCollapse, onClick, groups }) {
  const [sort, setSort] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [dropdownSelection, setDropdownSelection] = useState("All");
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [sortedGroups, setSortedGroups] = useState(groups);
  const [updateGroups, setUpdateGroups] = useState(true);

  useEffect(() => {
    if (updateGroups) {
      setSortedGroups(groups);
    }
  }, [groups]);

  const getSortValue = (sortCategory) => {
    let direction;
    let currentCategory = sort.split("-")[0];
    let currentDirection = sort.split("-")[1];
    if (currentCategory == sortCategory) {
      if (currentDirection == "asc") {
        direction = "asc";
      } else {
        direction = "desc";
      }
    } else {
      direction = null;
    }

    return direction;
  };

  const handleSearch = (term) => {
    setSearchTerm(term);
  };

  // Update iboSearchParams for dropdown filtering
  const handleDropDownSelection = (pin) => {
    setDropdownSelection(pin);
  };

  const handleUpdateFilters = () => {
    const filteredGroups = groups.filter((group) => {
      const matchesSearchTerm = group.name
        .toLowerCase()
        .includes(searchTerm.toLowerCase());
      const matchesDropdownSelection =
        dropdownSelection === "All" || group.type === dropdownSelection;

      return matchesSearchTerm && matchesDropdownSelection;
    });

    setSortedGroups(filteredGroups);
  };

  const handleSort = (field, direction) => {
    setSort(`${field}-${direction}`);
    setSortedGroups((prevGroups) =>
      [...prevGroups].sort((a, b) => {
        if (direction === "asc") {
          return a[field].localeCompare(b[field]);
        } else {
          return b[field].localeCompare(a[field]);
        }
      })
    );
  };

  // Handle selecting and deselecting groups
  const handleGroupSelection = (group) => {
    setSelectedGroups((prev) =>
      prev.some((s) => s.id === group.id)
        ? prev.filter((s) => s.id !== group.id)
        : [...prev, group]
    );
  };

  // Handle form submission
  const handleAddGroups = () => {
    console.log(selectedGroups);
    onClick(selectedGroups);
    onCollapse();
  };

  const [selectOptions, setSelectOptions] = useState(["All"]);

  useEffect(() => {
    // Extract unique group types and update selectOptions
    const uniqueGroupTypes = Array.from(
      new Set(groups.map((group) => group.type))
    );
    setSelectOptions(["All", ...uniqueGroupTypes]);
  }, [groups]);

  return (
    <div className={styles.container}>
      <Modal
        title="SELECT GROUP"
        onClose={() => onCollapse()}
        isOpen={isOpen}
        className={styles.modal}
        children={
          <>
            <div className={styles.filterContainer}>
              <div className={classNames(styles.flexCol, styles.typeCon)}>
                <span className={styles.title}>Type</span>
                <div className={styles.type}>
                  <Select
                    placeholder="All"
                    options={selectOptions}
                    onChange={(value) => handleDropDownSelection(value)}
                  />
                </div>
              </div>
              <div className={classNames(styles.flexCol, styles.search)}>
                <SearchBar
                  placeholderText="Click to type"
                  onInput={(value) => handleSearch(value)}
                />
              </div>
              <div className={classNames(styles.flexCol, styles.button)}>
                <Button
                  children="FILTER"
                  onClick={() => handleUpdateFilters()}
                />
              </div>
            </div>
            <div className={styles.speakersListOutput}>
              <span className={styles.tableTitle}>Groups</span>
              <div className={styles.hr} />
              <table className={styles.tableContainer}>
                <tbody>
                  <tr className={styles.tableHeader}>
                    <th className={styles.checkboxHeader} />
                    <th
                      className={classNames(
                        styles.tableSorter,
                        styles.nameHeader
                      )}
                    >
                      <TableSortHeader
                        value={
                          sort === "name-asc"
                            ? "asc"
                            : sort === "name-desc"
                            ? "desc"
                            : null
                        }
                        onChange={
                          (newValue) => handleSort("name", newValue) // Set the newValue as "asc" or "desc"
                        }
                        children="Name"
                      />
                    </th>
                    <th
                      className={classNames(
                        styles.tableSorter,
                        styles.typeHeader
                      )}
                    >
                      <TableSortHeader
                        value={
                          sort === "type-asc"
                            ? "asc"
                            : sort === "type-desc"
                            ? "desc"
                            : null
                        }
                        onChange={
                          (newValue) => handleSort("type", newValue) // Set the newValue as "asc" or "desc"
                        }
                        children="Type"
                      />
                    </th>
                    <th
                      className={classNames(
                        styles.tableSorter,
                        styles.descriptionHeader
                      )}
                    >
                      Description
                    </th>
                  </tr>

                  <tbody className={styles.iboList}>
                    {sortedGroups.map((group, key) => (
                      <GroupItem
                        group={group}
                        key={key}
                        onSelect={() => handleGroupSelection(group)}
                      />
                    ))}
                  </tbody>
                </tbody>
              </table>
            </div>
            <div className={styles.addButton}>
              <Button children="ADD" onClick={() => handleAddGroups()} />
            </div>
          </>
        }
      />
    </div>
  );
}

AddGroupModal.propTypes = {
  /**
   * Getter to determine if modal is displayed
   */
  isOpen: PropTypes.bool,
  /**
   * Callback function for when the modal is closed
   */
  onCollapse: PropTypes.func,
  /**
   * Function called when the add btn is clicked (opens the confirm speaker modal)
   */
  onClick: PropTypes.func,
  /**
   * Array of speakers to display
   */
  speakers: PropTypes.arrayOf(PropTypes.shape({})),
};
