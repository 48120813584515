import React from "react";
import PropTypes from "prop-types";
import Checkbox from "shared/@forms/Checkbox";
import { Link } from "react-router-dom";
import styles from "./SpeakerItem.module.css";
import classNames from "classnames";

/**
 * Displays the information of a speaker and the ability to select them.
 */
export function SpeakerItem({ speaker, onSelect }) {
  return (
    <tr className={styles.speakerItemRow}>
      <td className={styles.checkbox}>
        <Checkbox onChange={() => onSelect(speaker)} />
      </td>

      <td className={classNames(styles.item, styles.name)}>
        <Link to={"/events/events-details"}>{speaker.displayName}</Link>
      </td>

      <td className={classNames(styles.item, styles.iboNumber)}>{speaker.iboNumber}</td>
      <td className={classNames(styles.item, styles.userName)}>{speaker.userName}</td>
      <td className={classNames(styles.item, styles.email)}>{speaker.email}</td>
      <td className={classNames(styles.item, styles.lastScheduled)}>{speaker.lastScheduled}</td>
      <td className={classNames(styles.item, styles.pinLevel)}>{speaker.pinLevel}</td>
    </tr>
  );
}

SpeakerItem.propTypes = {
  /**
   * Object with the speakers information. (name-String, iboNum-String, userName-String, email-String, lastScheduled-String, pinLevel-String)
   */
  speaker: PropTypes.shape({
    name: PropTypes.string,
    iboNum: PropTypes.string,
    userName: PropTypes.string,
    email: PropTypes.string,
    lastScheduled: PropTypes.string,
    pinLevel: PropTypes.string,
  }),
  /**
   * Function called when the speaker is selected
   */
  onSelect: PropTypes.func,
};
