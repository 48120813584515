import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Plus } from "react-ikonate";

import styles from "./ImagePicker.module.css";
import classNames from "classnames";

/**
 * Interactive component that asks the user for access to their device and opens an image uploader
 */
export function ImagePicker({ changeImageItem, isADA = false, adaIMGSRC }) {
  const [uploadedImage, setUploadedImage] = useState(null);

  const inputRef = useRef(null);

  const handleOpenFileBrowser = () => {
    inputRef.current.click();
  };

  const handleImageUpload = (event) => {
    console.log(event.target.files);
    validateUpload(event.target.files[0]);
  };

  const validateUpload = (file) => {
    if (
      file.type == "image/png" ||
      file.type == "image/jpeg" ||
      file.type == "image/gif"
    ) {
      setUploadedImage(file);
    }
  };

  useEffect(() => {
    changeImageItem(uploadedImage);
  }, [uploadedImage]);

  return (
    <div className={styles.imagePickerContainer}>
      {adaIMGSRC || uploadedImage ? (
        // Display this block if adaIMGSRC or uploadedImage exists
        <>
          {isADA && adaIMGSRC ? (
            <>
              <img
                src={adaIMGSRC}
                className={styles.adaIcon}
                alt="ADA Image"
                onClick={() => {
                  handleOpenFileBrowser();
                }}
              />
              <input
                style={{ display: "none" }}
                ref={inputRef} // Input ref for file browser
                type="file"
                id="imgPicker"
                name="imgPicker"
                accept="image/*"
                onChange={(event) => {
                  handleImageUpload(event);
                }}
              />
            </>
          ) : (
            <>
              <label className={styles.imagePickerLabel}>Uploaded Photo</label>
              <div className={styles.imageName}>{uploadedImage.name}</div>
            </>
          )}
        </>
      ) : (
        // Display this block if neither adaIMGSRC nor uploadedImage exists
        <>
          {!isADA && (
            <label className={styles.imagePickerLabel} htmlFor="imgPicker">
              Upload Photo
            </label>
          )}
          <input
            style={{ display: "none" }}
            ref={inputRef}
            type="file"
            id="imgPicker"
            name="imgPicker"
            accept="image/*"
            onChange={(event) => {
              handleImageUpload(event);
            }}
          />

          <button
            className={classNames(
              styles.imagePickerUploadButton,
              isADA ? styles.imagePickerUploadButtonSmall : ""
            )}
            type="file"
            id="imgPicker"
            name="imgPicker"
            accept="image/*"
            onClick={() => {
              handleOpenFileBrowser();
            }}
          >
            <Plus
              className={classNames(
                styles.imagePickerPlus,
                isADA ? styles.imagePickerPlusSmall : ""
              )}
              borderWidth={5}
            />
          </button>
        </>
      )}
    </div>
  );
}

ImagePicker.propTypes = {
  /**
   * Function that executes the saving of new image to the global variable
   */
  changeImageItem: PropTypes.func,
};
