import React from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import EventOptions from "Events/Components/EventOptions";
import Card from "shared/Card";
import EventImage from "Events/Components/EventImage";
import styles from "./EventCard.module.css";
import classNames from "classnames";
import formatDate from "shared/@utils/formatDate";

/**
 * Component that displays the events information
 */
export function EventCard({ event, onClone, onArchive }) {
  const history = useHistory();
  const isEventArchived = event.status === "Archived";

  const handleView = () => {
    switch (event.ruleset) {
      case "Major v1.0":
        history.push(`view/event/majorv1/${event.id}`);
        break;
      case "Local v1.0":
        history.push(`view/event/localv1/${event.id}`);
        break;
      case "LOS v1.0":
        history.push(`view/event/losv1/${event.id}`);
        break;
      case "Major v2.0":
        history.push(`view/event/majorv2/${event.id}`);
        break;
      case "Local Regional v2.0":
        history.push(`view/event/localv2/${event.id}`);
        break;
      case "Local SLM v2.0":
        history.push(`view/event/localv2/${event.id}`);
        break;
      case "LOS v2.0":
        history.push(`view/event/losv2/${event.id}`);
        break;
      default:
        history.push(`view/event/${event.id}`);
    }
  };

  const handleEdit = () => {
    switch (event.ruleset) {
      case "Major v1.0":
        history.push(`edit/event/majorv1/${event.id}`);
        break;
      case "Local v1.0":
        history.push(`edit/event/localv1/${event.id}`);
        break;
      case "LOS v1.0":
        history.push(`edit/event/losv1/${event.id}`);
        break;
      case "Major v2.0":
        history.push(`edit/event/majorv2/${event.id}`);
        break;
      case "Local Regional v2.0":
        history.push(`edit/event/localv2/${event.id}`);
        break;
      case "Local SLM v2.0":
        history.push(`edit/event/localv2/${event.id}`);
        break;
      case "LOS v2.0":
        history.push(`edit/event/losv2/${event.id}`);
        break;
      default:
        history.push(`edit/${event.id}`);
    }
  };

  const handleArchive = () => {
    onArchive(event.id);
  };
  const cardContents = (
    <div className={styles.eventCardContents}>
      <div className={styles.dateAndImageContainer}>
        <div className={styles.eventImage}>
          {event.ruleset.includes("Major") && (
            <EventImage image={event.image} ruleset="Major" />
          )}
          {event.ruleset.includes("Local") && (
            <EventImage image={event.image} ruleset="Local" />
          )}
          {event.ruleset.includes("LOS") && (
            <EventImage image={event.image} ruleset="LOS" />
          )}
        </div>
      </div>

      <div className={styles.eventInfoContainer}>
        <div className={classNames(styles.eventInfoItem, styles.bold)}>
          {event.name +
            " - " +
            (event.venue == "onsite" ? "Onsite" : "Virtual")}
        </div>

        {event.title ? (
          <div className={styles.eventInfoName}>{event.title}</div>
        ) : (
          <div className={styles.eventInfoName}>Display Name: Not Found</div>
        )}

        {event.startDate ? (
          <div className={styles.eventInfoDate}>
            {formatDate(event.startDate, "MM/DD/YYYY h:mm A")} PT
            {event.endDate && " - "}
            {event.endDate &&
              formatDate(event.endDate, "MM/DD/YYYY h:mm A")}{" "}
            {event.endDate && "PT"}
          </div>
        ) : (
          <div className={styles.eventInfoDate}>
            Start / End Date: Not Found
          </div>
        )}

        <div className={styles.eventInfoItem}>{event.location}</div>
      </div>

      <div className={styles.ticketsSoldInfo}>
        <div>
          {isNaN(event.ticketsSold) ||
          isNaN(event.totalTickets) ||
          event.ticketsSold == 0 ||
          event.totalTickets == 0
            ? ""
            : event.ticketsSold + "/" + event.totalTickets}
        </div>
      </div>

      <div className={styles.status}>{event.status}</div>

      <div className={styles.eventOptions}>
        <EventOptions
          onView={() => handleView()}
          onEdit={() => handleEdit()}
          onClone={onClone}
          onArchive={() => handleArchive()}
          isArchived={isEventArchived}
        />
      </div>
    </div>
  );

  return <Card className={styles.eventCardContainer} children={cardContents} />;
}

EventCard.propTypes = {
  /**
   * Object with the event info (startDate - String, endDate - String, image-String, name-String, location-String, jobCode-String, ticketsSold-Number, totalTickets-Number, and status-String)
   */
  event: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    image: PropTypes.string,
    name: PropTypes.string,
    location: PropTypes.string,
    jobCode: PropTypes.string,
    ticketsSold: PropTypes.number,
    totalTickets: PropTypes.number,
    status: PropTypes.string,
  }),
};
